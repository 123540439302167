import React, { Component, forwardRef } from "react";
import axios from "axios";
import numeral from "numeral";
import { CSVDownload, CSVLink } from "react-csv";
import { awsUrl2, awsUrl, awsUrlBookUpload } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import Loader from "../common/Loader";
import { Nav } from "react-bootstrap";
import Header from "../common/Header";
import { Auth } from "aws-amplify";
import SideTab from "../../newcomponent/common/SideTab";
import ToggleSwitch from "../../utils/ToggleSwitch";
import Popup from "../common/Popup";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import toast, { Toaster } from "react-hot-toast";
import {
  saveInUserstatusTable,
  saveInUserTable,
} from "../../utils/form_compPro";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { alpha } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { setCommentBoxColor } from "../../utils/common";
import { RotatingLines } from "react-loader-spinner";
import SaveAlt from "@material-ui/icons/SaveAlt";
import parse from "html-react-parser";
import { Modal } from "react-bootstrap";
import { DownloadDashboardFiles } from "../subcompo/DownloadDashboardFiles";
import {
  createCommentForDownload,
  createSubmissionForDownload,
} from "../common/dashboard_helper";

import { carrierMapping } from "../../utils/carrierList.json";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";

let ismobile_font = window.innerWidth < 1600;
const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  // Filter: forwardRef((props,ref) => <Filter {...props} ref={ref} />)
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: () => <></>,
};

let isMobile_sidenav = window.innerWidth < 900;
class DashboardLibertate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "tab1",
      modalBody: [],
      openModal: false,
      agentButton: false,
      downloadData: [],
    };
  }

  setSelected = (tab) => {
    let selected = this.state.state;
    if (selected !== tab) {
      this.setState({ selected: tab });
    }
  };

  handleAgentCommentsButtonClick = (comments, submittedDate) => {
    this.setState({ agentButton: true });
    if (typeof comments[0] === "string" && comments[0] !== "") {
      let updatedComment = [
        {
          ...initialcomment,
          comment: comments[0],
          commentTimestamp: submittedDate,
        },
      ];
      this.setState({ modalBody: updatedComment });
    } else if (typeof comments[0] === "object") {
      this.setState({ modalBody: comments });
    } else {
      this.setState({ modalBody: [initialcomment] });
    }

    this.setState({ openModal: true });
  };

  handleUwCommentHistory = (rowdata, rowkey) => {
    this.setState({ agentButton: false });
    rowdata.forEach((item) => {
      if (item.user_email_id === rowkey) {
        if (typeof item.uw_comments?.[0] === "object") {
          this.setState({ modalBody: item.uw_comments });
        } else if (typeof item.agent_comments?.[0] === "string") {
          let updatedComment = [
            {
              ...initialcomment,
              comment: item.uw_comments[0],
              commentTimestamp: item.uploadTimestamp,
            },
          ];
          this.setState({ modalBody: updatedComment });
        }
      }
    });
    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  handleDownload = async (event) => {
    try {
      let lastEvalKey = null, allRows = [];

      do {
        await new Promise((_res, _rej) => {

          axios
            .post(awsUrlBookUpload + "/api/getBookRollCsvData", { lastEvaluatedKey: lastEvalKey })
            .then((resp) => {

              let _csv_row = resp?.data?.csvRows || [];

              // console.log("response: ", _csv_row);

              for (let row of _csv_row) {

                allRows.push(row);

              }

              lastEvalKey = resp.data.lastEvaluatedKey;

              _res();
            }).catch((err) => { console.log(err); _rej() });
        });

      } while (lastEvalKey);

      // console.log(allRows);

      this.setState({ downloadData: allRows });

    } catch (error) { }
  };

  getDownloadData = () => {
    return this.state.downloadData;
  };

  render() {
    let { selected, modalBody, openModal, agentButton } = this.state;
    const tabsMap = {
      tab1: "Book Roll",
    };

    return (
      <div className="dashboard-container">
        <div
          className="d-flex bg-light"
          style={{ borderBottomStyle: "groove" }}
        >
          <Header />
        </div>

        <div className="position-relative">
          {isMobile_sidenav ? <SideTab /> : " "}

          <div className="tabs  lib_dashboard">
            <div className="d-flex justify-content-center mb-3 text-center">
              <h2 className="mt-3 mb-3">
                <b>Dashboard</b>
                {this.state.downloadData.length > 0 && (
                  <CSVDownload data={this.state.downloadData} target="_blank" />
                )}
                {/* <CSVLink
                  asyncOnClick={true}
                  data={this.getDownloadData}
                  onClick={this.handleDownload}
                >
                  Download me
                </CSVLink> */}

                <button
                  className="btn"
                  filename={"my-file.csv"}
                  onClick={this.handleDownload}
                >
                  ⬇
                </button>
                {/* dataDownloaded */}
              </h2>
            </div>
            <Nav
              className="userdashboard__button"
              variant="pills"
              defaultActiveKey={selected}
              style={{ fontSize: "16px" }}
            >
              {Object.keys(tabsMap).map((tab) => (
                <Nav.Item>
                  <Nav.Link
                    eventKey={tab}
                    onClick={() => this.setSelected(tab)}
                  >
                    {tabsMap[tab]}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            {Object.keys(tabsMap).map((tab) => (
              <div key={tab} className="Admin__Dashboard">
                {
                  <LibertateDashboard
                    tabName={tab}
                    selected={selected}
                    modalBody={modalBody}
                    openModal={openModal}
                    agentButton={agentButton}
                    handleAgentCommentsButtonClick={
                      this.handleAgentCommentsButtonClick
                    }
                    handleUwCommentHistory={this.handleUwCommentHistory}
                    handleCloseModal={this.handleCloseModal}
                  />
                }
              </div>
            ))}
          </div>
        </div>
        <Loader />
      </div>
    );
  }
}

class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      loggedInEmail: "",
    };
  }
  onChangeHandler(target) {
    this.setState({ value: target });
  }

  componentDidMount() {
    this.loggedInEmailId();
  }

  loggedInEmailId = async () => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      console.log("++sd", sessionData);
      this.setState({ loggedInEmail: sessionData["attributes"]["email"] });
    } catch (error) {
      console.error("Error fetching authenticated user:", error);
    }
  };

  render() {
    let { value, loggedInEmail } = this.state;
    return (
      <textarea
        value={value}
        className="underwriter_comment"
        rows="1"
        style={{
          width: "100%",
          backgroundColor: setCommentBoxColor(this.props.comments),
        }}
        onChange={(e) => this.onChangeHandler(e.target.value)}
        onBlur={(e) => {
          let agentCommentsCopy = this.props.agentCommentsHistory;

          let currentTimestamp = moment().valueOf();
          let dataToSave = {};

          if (agentCommentsCopy === undefined && e.target.value.trim() !== "") {
            agentCommentsCopy = [
              {
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                email: loggedInEmail,
              },
            ];
          } else if (
            e.target.value.length === 0 ||
            e.target.value.trim() === ""
          ) {
            if (
              agentCommentsCopy[0]?.comment &&
              e.target.value.trim() !== agentCommentsCopy[0]?.comment
            ) {
              agentCommentsCopy.unshift({
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                previousComment: "true",
                email: loggedInEmail,
              });
            } else if (
              agentCommentsCopy[0] &&
              typeof agentCommentsCopy[0] === "string"
            ) {
              e.target.value.trim() !== agentCommentsCopy[0] &&
                agentCommentsCopy.unshift({
                  comment: e.target.value.trim(),
                  commentTimestamp: currentTimestamp,
                  previousComment: "true",
                  email: loggedInEmail,
                });
            } else {
              console.log("I m returning as value is 0");
              return;
            }
          } else if (typeof agentCommentsCopy[0] === "string") {
            e.target.value.trim() !== agentCommentsCopy[0] &&
              agentCommentsCopy.unshift({
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                email: loggedInEmail,
              });
          } else {
            e.target.value.trim() !== agentCommentsCopy[0]?.comment &&
              agentCommentsCopy.unshift({
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                email: loggedInEmail,
              });
          }

          this.props.handleAgentCommentHistory(
            this.props.rowData,
            agentCommentsCopy
          );
          dataToSave.user_email_id = this.props.rowData.user_email_id;
          dataToSave.sortKeyList = this.props.rowData.uuidCarriers;
          dataToSave.agent_comments = agentCommentsCopy;
          dataToSave.agent_comments && this.props.callback(dataToSave);
        }}
      ></textarea>
    );
  }
}

let initialcomment = {
  comment: "",
  commentTimestamp: "",
  email: "",
};
class LibertateDashboard extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isLoading: true,
      scrollLeft: false,
      scrollRight: true,
      submissionYesNoList: {},
      agentCommentsHistory: {},
      popupData: {
        title: "",
        children: <></>,
        acceptBtn: "",
        acceptBtnCallback: () => { },
        rejectBtnCallback: () => { },
        show: false,
      },
      loggedInGroups: [],
      dataLoading: true,
      populateFlag: false,
      modalData: {},
    };
  }

  componentDidMount = () => {
    sessionStorage.clear();
    this.updateDisplay();
  };

  camelCase = (str) => {
    if (!str) return "";
    return str
      .trim()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  onClickCompanyName = (rowData) => {
    sessionStorage.clear();
    console.log("Suraj 123", rowData);

    if (rowData.amtrustAccountDetails) {
      sessionStorage.setItem(
        "amtrustAccountDetails",
        JSON.stringify(rowData.amtrustAccountDetails)
      );
    }
    if (rowData.amtrustAccountDetailsCopy) {
      sessionStorage.setItem(
        "amtrustAccountDetailsCopy",
        JSON.stringify(rowData.amtrustAccountDetailsCopy)
      );
    }

    let currProspect = {
      childrenLoc: rowData.childrenLoc,
      companyProfile: rowData.companyProfile,
      emodStatesData: rowData.emodStatesData,
      riskProfile: rowData.riskProfile,
      peoDetails: rowData.peoDetails,
      carrierList: rowData.carrierList,
    };

    if (rowData.uw_ques) {
      currProspect["uwQues"] = rowData.uw_ques;
    }

    if (rowData.salesforceData) {
      sessionStorage.setItem(
        "salesforceData",
        JSON.stringify(rowData.salesforceData)
      );
    }

    if (rowData.status === "quote_generated") {
      sessionStorage.setItem("quoteData", JSON.stringify(rowData.quoteData));
      sessionStorage.setItem("address", JSON.stringify(rowData.address));
      sessionStorage.setItem("formStage", "three");
      if (rowData.quoteSubmitted) {
        sessionStorage.setItem("hideButtons", "true");
      }
    } else if (rowData.status === "company_profile") {
      sessionStorage.setItem("formStage", "two");
    }

    sessionStorage.setItem("currProspect", JSON.stringify(currProspect));
    sessionStorage.setItem("user_id", rowData.user_email_id);

    window.location.href = "/Quote";
  };

  updateData = (data) => {
    new Promise((resolve, reject) => {
      try {
        axios
          .post(awsUrl + "/api/underWriterData/update", data)
          .then((res) => {
            console.log("data saved");
            resolve();
          })
          .catch((err) => {
            console.log("error while saving", err);
            reject();
          });
      } catch (error) { }
    });
  };

  updateDataInUser = (data) => {
    new Promise((resolve, reject) => {
      try {
        let data_req = JSON.parse(JSON.stringify(data));
        if (data_req.sortKeyList) {
          delete data_req.sortKeyList;
        }
        axios
          .post(awsUrl2 + "/api/userTableData/update", data_req)
          .then((res) => {
            console.log("data saved");
            resolve();
          })
          .catch((err) => {
            console.log("error while saving", err);
            reject();
          });
      } catch (error) { }
    });
  };

  dateSort = (a, b, field) => {
    let momentA = moment(a[field], "M/D/YY");
    let momentB = moment(b[field], "M/D/YY");

    if (
      (!momentA.isValid() && momentB.isValid()) ||
      momentA.isBefore(momentB)
    ) {
      return -1;
    } else if (
      (momentA.isValid() && !momentB.isValid()) ||
      momentA.isAfter(momentB)
    ) {
      return 1;
    } else {
      return 0;
    }
  };

  customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };

  scrollLeft = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));
    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft -= 150;
      if (tableContainerDiv.scrollLeft == 0) {
        this.setState({ scrollLeft: false });
        this.setState({ scrollRight: true });
      }

      const maxScrollLeft =
        tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

      if (tableContainerDiv.scrollLeft < maxScrollLeft) {
        this.setState({ scrollRight: true });
      }
    }
  };

  scrollRight = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));

    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft += 150;
      this.setState({ scrollLeft: true });
    }

    const maxScrollLeft =
      tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

    if (tableContainerDiv.scrollLeft > maxScrollLeft) {
      this.setState({ scrollRight: false });
    }
  };

  fontfunciton = () => {
    if (window.innerWidth < 1600 && window.innerWidth > 1500) {
      return "0.84rem";
    }
    if (window.innerWidth < 1500 && window.innerWidth > 1410) {
      return "0.8rem";
    }
    if (window.innerWidth < 1410 && window.innerWidth > 1310) {
      return "0.76rem";
    }
    if (window.innerWidth < 1300) {
      return "0.72rem";
    }
  };

  customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };

  formatFein = (value) => {
    if (!value) return "-";
    let fein = "-";
    value = value?.split("-")?.join("");
    var len = value?.length,
      dummystr = "000000000";

    if (len === 0) {
      fein = "-";
    } else if (len > 0 && len < 9) {
      value = dummystr.slice(0, 9 - len) + "" + value;
      fein = value[0] + value[1] + "-" + value?.slice(2);
    } else {
      fein = value[0] + value[1] + "-" + value?.slice(2);
    }

    return fein;
  };

  handleAgentCommentHistory = (rowData, data) => {
    this.setState((prevstate) => {
      let agentCommentsHistory = JSON.parse(
        JSON.stringify(prevstate.agentCommentsHistory)
      );
      agentCommentsHistory[rowData?.user_email_id] = data;
      return { agentCommentsHistory: agentCommentsHistory };
    });
  };

  toggleSwitchHandlerInDashboard = async (valueCheck, rowData) => {
    let value = valueCheck ? "false" : "true";
    let req_body_userstatus = {
      user_email_id: rowData?.user_email_id,
      real_submission: value,
      address: rowData?.address,
      timestamp: rowData?.quoteData?.date,
      uuid: rowData?.quoteData?.uuid,
      peo: rowData.peoDetails.selectedPeo,
    };
    let req_body_users = {
      user_email_id: rowData?.user_email_id,
      real_submission: value,
    };
    let result1 = saveInUserstatusTable(req_body_userstatus);
    let result2 = saveInUserTable(req_body_users);

    Promise.all([result1, result2])
      .then(([result1, result2]) => { })
      .catch((err) => {
        console.log(err);
      });

    this.setState((prevstate) => {
      let submissionYesNoList = JSON.parse(
        JSON.stringify(prevstate.submissionYesNoList)
      );
      submissionYesNoList[rowData?.user_email_id] = valueCheck ? false : true;
      return { submissionYesNoList: submissionYesNoList };
    });
  };

  selectedPeoFormat = (selectedPeo) => {
    let displayPEOName = selectedPeo?.toLowerCase();
    if (selectedPeo === "insurecomp") {
      displayPEOName = "LIB";
    } else if (selectedPeo === "esg halcyon") {
      displayPEOName = "ESG Halcyon";
    } else if (selectedPeo === "express hr" || selectedPeo === "expresshr") {
      displayPEOName = "Express HR";
    } else if (selectedPeo === "gregory") {
      displayPEOName = "J Gregory";
    } else if (selectedPeo === "totalsolution") {
      displayPEOName = "Total Solution";
    } else if (selectedPeo === "payrollmadeeasy") {
      displayPEOName = "Payroll Made Easy";
    } else if (selectedPeo === "stafflink") {
      displayPEOName = "Prestige";
    }
    displayPEOName = displayPEOName?.replace(/\b\w/g, (char) =>
      char?.toUpperCase()
    );

    // Capitalize entire word if it's only 4 or less than 4 letters
    if (displayPEOName?.length <= 4) {
      displayPEOName = displayPEOName?.toUpperCase();
    }
    return displayPEOName;
  };

  setModalData = (data) => {
    this.setState({ modalData: data });
  };

  setTableData = () => {
    let {
      rowDataList,
      isLoading,
      scrollLeft,
      scrollRight,
      submissionYesNoList,
      agentCommentsHistory,
      loggedInGroups,
    } = JSON.parse(JSON.stringify(this.state));
    let {
      tabName,
      handleAgentCommentsButtonClick,
      handleUwCommentHistory,
    } = this.props;

    let columns = {
      tab1: [
        // {
        //   title: "Visit Date",
        //   field: "uploadTimestamp",
        //   defaultSort: "desc",
        //   filtering: true,
        //   filterPlaceholder: "Filter",
        //   render: (rowData) => {
        //     let visitDate = rowData.uploadTimestamp;
        //     return <span>{visitDate}</span>;
        //   },
        //   customFilterAndSearch: (term, rowData, field) =>
        //     this.customFilterAll(term, rowData, field),
        //   customSort: (a, b) => this.dateSort(a, b, "uploadTimestamp"),
        //   cellStyle: {
        //     wordBreak: "break-all",
        //     padding: " 6px 15px 6px 10px",
        //     minWidth: "150px",
        //   },
        //   headerStyle: {
        //     padding: " 10px 15px 10px 10px",
        //     minWidth: "150px",
        //   },
        // },
        {
          title: "Company Name",
          field: "companyName",
          // filtering: true,
          filterPlaceholder: "Filter",
          // cellStyle: {
          //   padding: "2px 0px 2px 12px",
          //   minWidth: "200px",
          //   maxWidth: "410px",
          // },
          // headerStyle: {
          //   padding: "2px 0px 2px 12px",
          //   minWidth: "200px",
          //   maxWidth: "410px",
          // },
          // filterCellStyle: {
          //   // padding: "5px 0px 5px 4px",
          // },
          render: (rowData) => (
            <Clamp lines={2}>
              <span>
                <div
                // onClick={() => this.onClickCompanyName(rowData)}
                // className="company-name"
                >
                  {this.camelCase(rowData.companyName)}
                </div>
              </span>
            </Clamp>
          ),
          customSort: (a, b) => {
            const nameA = a.companyName.toUpperCase().trim();
            const nameB = b.companyName.toUpperCase().trim();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          },
        },
        {
          title: "FEIN",
          field: "fein",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),

          // cellStyle: {
          //   wordBreak: "break-all",
          //   padding: "2px 0px 2px 2px",
          //   minWidth: "100px",
          // },
          // headerStyle: {
          //   padding: "2px 0px 2px 2px",
          //   minWidth: "100px",
          // },
          render: (rowData) => (
            <Clamp lines={1}>{this.formatFein(rowData?.fein) || ""}</Clamp>
          ),
        },
        // {
        //   title: "Contact Name",
        //   field: "contactName",
        //   filtering: true,
        //   filterPlaceholder: "Filter",
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "200px",
        //     maxWidth: "300px",
        //   },
        //   headerStyle: {
        //     padding: " 0px 0px 0px 10px",
        //     minWidth: "200px",
        //     maxWidth: "300px",
        //   },
        //   render: (rowData) => <Clamp lines={1}>{rowData.contactName}</Clamp>,
        // },
        // {
        //   title: "Gov CC State",
        //   field: "govCCState",
        //   filtering: true,
        //   filterPlaceholder: "Filter",
        //   cellStyle: {
        //     padding: "2px 0px 2px 6px",
        //     minWidth: "120px",
        //     width: "90px",
        //   },
        //   headerStyle: {
        //     padding: " 0px 0px 0px 10px",
        //     minWidth: "60px",
        //     width: "60px",
        //   },

        //   render: (rowData) => (
        //     <Clamp lines={1}>{rowData.govCCState || "-"}</Clamp>
        //   ),
        // },
        // {
        //   title: "Primary State",
        //   field: "state",
        //   filtering: true,
        //   filterPlaceholder: "Filter",
        //   cellStyle: {
        //     padding: "2px 0px 2px 6px",
        //     minWidth: "120px",
        //     width: "60px",
        //     textAlign: "center",
        //   },
        //   headerStyle: {
        //     padding: " 0px 0px 0px 10px",
        //     minWidth: "60px",
        //     width: "60px",
        //     textAlign: "center",
        //   },

        //   render: (rowData) => <Clamp lines={1}>{rowData.state || "-"}</Clamp>,
        // },
        // {
        //   title: "Class Code",
        //   field: "govClassCode",
        //   // customFilterAndSearch: (term, rowData, field) =>
        //   //   this.customFilterfunction(term, rowData, field),

        //   filtering: true,
        //   filterPlaceholder: "Filter",
        //   cellStyle: {
        //     padding: "2px 0px 2px 6px",
        //     minWidth: "120px",
        //     width: "40px",
        //     textAlign: "center",
        //   },
        //   headerStyle: {
        //     padding: " 0px 0px 0px 10px",
        //     minWidth: "40px",
        //     width: "40px",
        //     textAlign: "center",
        //   },

        //   render: (rowData) => (
        //     <Clamp lines={1}>{rowData.govClassCode || "-"}</Clamp>
        //   ),
        //   customSort: (a, b) => {
        //     // Convert govClassCode to number for comparison
        //     const codeA = +a.govClassCode || 0;
        //     const codeB = +b.govClassCode || 0;
        //     return codeA - codeB;
        //   },
        // },
        {
          title: "Payroll",
          field: "payroll",
          render: (rowData) => numeral(rowData?.payroll).format("$0,0"),
          // customFilterAndSearch: (term, rowData, field) =>
          //   this.customFilterfunction(term, rowData, field),
          filtering: true,
          filterPlaceholder: "Filter",
          // cellStyle: {
          //   padding: "2px 0px 2px 6px",
          //   minWidth: "120px",
          //   // textAlign: "right",
          // },
          // headerStyle: {
          //   padding: "2px 0px 2px 6px",
          //   minWidth: "120px",
          //   //paddingLeft: "2rem",
          //   // textAlign: "center",
          // },
          render: (rowData) => (
            <div className="payrollBox">
              <Clamp lines={1}>{rowData.payroll}</Clamp>
            </div>
          ),
          customSort: (a, b) => {
            // Unformat payroll strings to numbers for comparison
            const payrollA = numeral(a.payroll).value() || 0;
            const payrollB = numeral(b.payroll).value() || 0;
            return payrollA - payrollB;
          },
        },
        // {
        //   title: "Phone Number",
        //   field: "phoneNumber",
        //   filtering: true,
        //   filterPlaceholder: "Filter",
        //   render: (rowData) =>
        //     rowData.phoneNumber
        //       ? rowData.phoneNumber.length == 10
        //         ? rowData.phoneNumber.replace(
        //             /(\d{3})(\d{3})(\d{4})/,
        //             "($1) $2-$3"
        //           )
        //         : rowData.phoneNumber.replace(
        //             /(\d{3})(\d{3})(\d{3})/,
        //             "($1) $2-$3"
        //           )
        //       : "-",
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "150px",
        //     width: "150px",
        //   },
        //   headerStyle: {
        //     padding: " 0px 0px 0px 10px",
        //     minWidth: "150px",
        //   },
        // },
        {
          title: "Effective Date",
          field: "effectiveDate",
          filtering: true,
          filterPlaceholder: "Filter",
          render: (rowData) => rowData.effectiveDate,
          customSort: (a, b) => this.dateSort(a, b, "effectiveDate"),
          // headerStyle: {
          //   padding: "2px 0px 2px 6px",
          //   minWidth: "120px",
          //   // textAlign: "center",
          // },
          // cellStyle: {
          //   padding: "2px 0px 2px 6px",
          //   minWidth: "120px",
          //   width: "120px",
          //   // textAlign: "center",
          // },
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
        },
        {
          title: "Distributing Partners",
          field: "selectedPeoToDisplay",
          filtering: true,
          filterPlaceholder: "Filter",
          // cellStyle: {
          //   padding: "2px 0px 2px 6px",
          //   minWidth: "180px",
          //   // textAlign: "center",
          // },
          // headerStyle: {
          //   padding: "2px 0px 2px 6px",
          //   minWidth: "180px",
          //   // textAlign: "center",
          // },
          render: (rowData) => (
            <Clamp lines={1}>{rowData.selectedPeoToDisplay || "-"}</Clamp>
          ),
        },
        // {
        //   title: "Modified By",
        //   field: "modifiedBy",
        //   filtering: true,
        //   filterPlaceholder: "Filter",
        //   render: (rowData) => (
        //     <Clamp lines={1}>
        //       {rowData.modifiedBy
        //         ? rowData.modifiedBy.split("@")[0].length <= 3
        //           ? rowData.modifiedBy.split("@")[0].toUpperCase()
        //           : rowData.modifiedBy.split("@")[0][0].toUpperCase() +
        //             rowData.modifiedBy.split("@")[0].slice(1)
        //         : ""}
        //     </Clamp>
        //   ),

        //   headerStyle: {
        //     padding: " 0px 0px 0px 10px",
        //     minWidth: "120px",
        //   },
        //   cellStyle: {
        //     padding: "2px 0px 2px 6px",
        //     minWidth: "120px",
        //     width: "120px",
        //   },
        // },
        {
          title: "Status",
          field: "step",
          filtering: true,
          filterPlaceholder: "Filter",
          // cellStyle: (e, rowData) => {
          //   let style = { padding: "6px 15px 6px 10px", minWidth: "110px" };
          //   let stepValue = rowData?.uwStatus || rowData?.step;
          //   if (stepValue === "Quote Generated") {
          //     style.backgroundColor = "#87FA4D";
          //   } else if (stepValue === "rejected") {
          //     style.backgroundColor = "#cb2e3e";
          //   } else if (stepValue === "onHold") {
          //     style.backgroundColor = "#ffbd16";
          //   } else if (stepValue === "Underwriting Page") {
          //     style.backgroundColor = "#fa6d3e";
          //   } else {
          //     style.backgroundColor = "#FFF";
          //   }
          //   return style;
          // },
          // cellStyle: {
          //   padding: "2px 0px 2px 6px",
          //   minWidth: "125px",
          // },
          // headerStyle: {
          //   padding: "2px 0px 2px 6px",
          //   minWidth: "110px",
          // },
          render: (rowData) => (
            <Clamp lines={1}>
              {rowData.uwStatus ||
                (rowData.quoteSubmitted ? "Submitted" : "") ||
                rowData.step}
            </Clamp>
          ),
          customSort: (a, b) => {
            // Define the priority of each status
            const statusOrder = {
              approved: 1,
              Bound: 2,
              Closed: 3,
              Declined: 4,
              onHold: 5,
              "Quote Generated": 6,
              Quoted: 7,
              rejected: 8,
              Submitted: 9,
              "Underwriting Page": 10,
              "UW Quest": 11,
            };
            // Get the status values
            const statusA =
              a.uwStatus || (a.quoteSubmitted ? "Submitted" : "") || a.step;
            const statusB =
              b.uwStatus || (b.quoteSubmitted ? "Submitted" : "") || b.step;
            // Get the order values, default to a large number for undefined statuses
            const orderA = statusOrder[statusA] || 100;
            const orderB = statusOrder[statusB] || 100;
            // Compare the order values
            return orderA - orderB;
          },
        },
        // {
        //   title: "UW Status",
        //   field: "uwStatus",
        //   filtering: true,
        //   filterPlaceholder: "Filter",
        //   render: (rowData) => this.camelCase(rowData.uwStatus),
        //   cellStyle: (e, rowData) => {
        //     let style = { padding: "6px 15px 6px 10px" };

        //     if (rowData.uwStatus === "approved") {
        //       style.backgroundColor = "#008e1b";
        //     } else if (rowData.uwStatus === "rejected") {
        //       style.backgroundColor = "#cb2e3e";
        //     } else if (rowData.uwStatus === "onHold") {
        //       style.backgroundColor = "#ffbd16"
        //     } else {
        //       style.backgroundColor = "#FFF";
        //     }

        //     return style;
        //   },
        //   headerStyle: {
        //     padding: " 0px 15px 0px 10px",
        //     minWidth: "100px",
        //   },
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "150px",
        //     width: "150px",
        //   },
        //   filterCellStyle: {
        //     padding: "5px 3px 5px 4px",
        //   }
        // },
        // {
        //   title: "UW Comment",
        //   field: "uw_comments_download",
        //   filtering: true,
        //   filterPlaceholder: "Filter",
        //   render: (rowData) => {
        //     return (
        //       <div className="d-flex">
        //         <textarea
        //           value={
        //             typeof rowData?.uw_comments?.[0] === "object"
        //               ? rowData?.uw_comments?.[0]?.comment
        //               : rowData?.uw_comments?.[0] || rowData?.uw_comments || ""
        //           }
        //           className="underwriter_comment"
        //           rows="1"
        //           disabled
        //           style={{
        //             width: "100%",
        //             backgroundColor: setCommentBoxColor(rowData?.uw_comments),
        //           }}
        //         ></textarea>
        //         <Tooltip title="Click to view comment history" arrow>
        //           <button
        //             className="comment-btn"
        //             onClick={
        //               () =>
        //                 handleUwCommentHistory(
        //                   [rowData],
        //                   `${rowData?.user_email_id}`
        //                 )
        //               // console.log([rowData], `${rowData.user_email_id}`)
        //             }
        //           >
        //             ...
        //           </button>
        //         </Tooltip>
        //       </div>
        //     );
        //   },
        //   headerStyle: {
        //     padding: " 0px 0px 0px 21px",
        //     minWidth: "240px",
        //   },
        //   filterCellStyle: {
        //     padding: "5px 5px 5px 14px",
        //   },
        //   cellStyle: {
        //     padding: "2px 9px 2px 6px",
        //     minWidth: "240px",
        //     width: "240px",
        //   },
        // },
        // {
        //   title: "Comments",
        //   field: "agent_comments_download",
        //   filtering: true,
        //   filterPlaceholder: "Filter",
        //   headerStyle: {
        //     padding: "0px 10px 0px 21px",
        //     minWidth: "240px",
        //   },
        //   filterCellStyle: {
        //     padding: "5px 5px 5px 14px",
        //   },
        //   cellStyle: {
        //     padding: "2px 10px 2px 6px",
        //     minWidth: "240px",
        //     width: "240px",
        //   },
        //   render: (rowData) => {
        //     const callbackFunction = (data) => {
        //       console.log("inside callback", data);
        //       console.log(rowData);
        //       this.updateData(data);
        //       this.updateDataInUser(data);
        //     };
        //     return (
        //       <div className="d-flex">
        //         <TextField
        //           key={`${rowData?.user_email_id}_comment`}
        //           value={
        //             typeof agentCommentsHistory[rowData?.user_email_id]?.[0] ===
        //             "object"
        //               ? agentCommentsHistory[rowData?.user_email_id]?.[0]
        //                   ?.comment
        //               : agentCommentsHistory[rowData?.user_email_id]?.[0]
        //           }
        //           callback={callbackFunction}
        //           rowData={rowData}
        //           agentCommentsHistory={
        //             agentCommentsHistory[rowData?.user_email_id]
        //           }
        //           handleAgentCommentHistory={this.handleAgentCommentHistory}
        //           comments={agentCommentsHistory[rowData?.user_email_id]}
        //         />
        //         <Tooltip title="Click to view comment history" arrow>
        //           <button
        //             className="comment-btn"
        //             onClick={
        //               () =>
        //                 handleAgentCommentsButtonClick(
        //                   agentCommentsHistory[rowData?.user_email_id],
        //                   rowData?.uploadTimestamp
        //                 )
        //               // console.log(
        //               //   rowData,
        //               //   rowData.agent_comments,
        //               //   agentCommentsHistory[rowData.user_email_id]
        //               // )
        //             }
        //           >
        //             ...
        //           </button>
        //         </Tooltip>
        //       </div>
        //     );
        //   },
        // },
        // {
        //   title: "Submission ?",
        //   field: "real_submission_download",
        //   filtering: true,
        //   filterPlaceholder: "Filter",
        //   sorting: true,
        //   cellStyle: {
        //     padding: "2px 0px 0px 22px",
        //     minWidth: "130px",
        //     maxWidth: "100px",
        //     textAlign: "center",
        //   },
        //   headerStyle: {
        //     padding: "2px 0px 0px 22px",
        //     minWidth: "130px",
        //     maxWidth: "100px",
        //     textAlign: "center",
        //   },
        //   filterCellStyle: {
        //     padding: "5px 0px 5px 4px",
        //   },
        //   render: (rowData) => (
        //     <Clamp lines={1}>
        //       <span>
        //         <ToggleSwitch
        //           id={rowData?.user_email_id}
        //           toggleSwitchCheck={submissionYesNoList[rowData.user_email_id]}
        //           toggleSwitchHandler={() => {
        //             this.toggleSwitchHandlerInDashboard(
        //               submissionYesNoList[rowData.user_email_id],
        //               rowData
        //             );
        //           }}
        //         />
        //       </span>
        //     </Clamp>
        //   ),
        //   customFilterAndSearch: (term, rowData) => {
        //     try {
        //       if (term.toLowerCase() == "yes") {
        //         return submissionYesNoList[rowData.user_email_id]
        //           ?.toString()
        //           ?.toLowerCase()
        //           ?.startsWith(true);
        //       }
        //       if (term.toLowerCase() == "no") {
        //         return submissionYesNoList[rowData.user_email_id]
        //           ?.toString()
        //           ?.toLowerCase()
        //           ?.startsWith(false);
        //       }
        //     } catch (err) {
        //       console.log("error", err);
        //     }
        //   },
        // },
        // {
        //   title: "Download",
        //   sorting: false,
        //   disableSortBy: true,
        //   cellStyle: {
        //     padding: "2px 0px 2px 6px",
        //     minWidth: "55px",
        //     textAlign: "center",
        //   },
        //   headerStyle: {
        //     textAlign: "right",
        //     padding: "6px 2px 4px 14px",
        //   },
        //   render: (rowData) => {
        //     let title = "";

        //     if (rowData?.fileList?.length) {
        //       for (let file of rowData?.fileList) {
        //         let fileToDisplay = file?.split("/");
        //         title =
        //           title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
        //       }
        //     }

        //     return (
        //       <button
        //         className="btn pt-0"
        //         type="button"
        //         title={parse(title)}
        //         data-html="true"
        //         onClick={() => {
        //           console.log(rowData);
        //           this.setModalData({
        //             showModel: true,
        //             modalTitle: <h1>{"Download"}</h1>,
        //             modalContent: (
        //               <>
        //                 <DownloadDashboardFiles
        //                   fileListToStore={rowData?.fileList}
        //                 />
        //               </>
        //             ),
        //           });
        //         }}
        //       >
        //         <img
        //           src={require("../../images/file-download-circle-green.svg")}
        //           alt=""
        //           style={
        //             rowData.fileList.length
        //               ? {
        //                   width: "30px",
        //                   filter: "grayscale(0)",
        //                 }
        //               : {
        //                   width: "30px",
        //                   filter: "grayscale(100%)  opacity(0.4)",
        //                 }
        //           }
        //         />
        //       </button>
        //     );
        //   },
        // },
      ],
    };
    if (loggedInGroups.includes(process.env.REACT_APP_SF_SYNC_GROUP)) {
      columns.tab1.push({
        title: "Salesforce",
        sorting: false,
        field: "salesforce",
        cellStyle: {
          padding: "2px 6px 2px 6px",
          minWidth: "70px",
        },
        render: (rowData) => {
          return (
            <button
              className="btn btn-sm btn-warning"
              disabled={!rowData?.salesforceData}
              onClick={(e) => {
                this.updatePopUpData({
                  show: true,
                  title: "",
                  children: (
                    <>
                      <br></br>
                      <span style={{ fontWeight: "bold" }}>
                        This action will sync the prospect back to SF. Do you
                        want to Proceed?
                      </span>
                    </>
                  ),
                  acceptBtn: "Yes",
                  acceptBtnCallback: async () => {
                    try {
                      this.sendToSalesforce(rowData);
                    } catch (error) {
                      console.log("error: ", error);
                    }
                  },
                });
              }}
            >
              Sync
            </button>
          );
        },
      });
    }

    return (
      <div style={{ position: "relative" }}>
        {/* {scrollLeft && (
          <button className="ChevronLeft" onClick={this.scrollLeft}>
            &lt;
          </button>
        )}
        {scrollRight && (
          <button className="ChevronRight" onClick={this.scrollRight}>
            &gt;
          </button>
        )} */}
        <MaterialTable
          isLoading={isLoading}
          icons={tableIcons}
          data={rowDataList}
          columns={columns[tabName]}
          // detailPanel={[
          //   {
          //     tooltip: "Status info",
          //     icon: () => <KeyboardArrowRightIcon />,
          //     render: (rowData) => {
          //       return (
          //         <div style={{ padding: "25px" }}>
          //           <TableContainer component={Paper}>
          //             <Table size="small">
          //               <TableHead
          //                 style={{
          //                   background: "#00000066",
          //                   color: "whitesmoke",
          //                 }}
          //               >
          //                 <TableRow>
          //                   <TableCell>Status</TableCell>
          //                   <TableCell>Date</TableCell>
          //                 </TableRow>
          //               </TableHead>
          //               <TableBody>
          //                 {Object.values(rowData.status_info_rows).map(
          //                   (element, index) => {
          //                     return (
          //                       <TableRow key={index}>
          //                         <TableCell scope="row">
          //                           {element.status}
          //                         </TableCell>
          //                         <TableCell scope="row">
          //                           {element.timestamp}
          //                         </TableCell>
          //                       </TableRow>
          //                     );
          //                   }
          //                 )}
          //               </TableBody>
          //             </Table>
          //           </TableContainer>
          //         </div>
          //       );
          //     },
          //   },
          // ]}
          options={{
            filtering: true,
            filter: true,
            paginationPosition: "both",
            padding: "dense",
            showTitle: false,
            thirdSortClick: false,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 75, 100],
            // exportFileName: "GetQuote Dashborad File",
            // exportButton: {
            //   csv: true,
            //   pdf: false,
            // },
            // headerStyle: {
            //   fontSize: "12px",
            //   backgroundColor: "#003764",
            //   color: "#FFF",
            //   padding: "1px 5px 1px 5px",
            //   fontFamily: "Montserrat",
            // },
            // rowStyle: {
            //   fontSize: "12px",
            //   fontFamily: "Montserrat",
            // },
            // searchFieldStyle: {
            //   fontSize: "12px",
            //   fontFamily: "Montserrat",
            // },
            headerStyle: {
              fontSize: ismobile_font ? this.fontfunciton() : "1rem",
              backgroundColor: "#003764",
              color: "#FFF",
              // padding: "6px 15px 6px 10px",
              fontFamily: "Montserrat",
            },
            rowStyle: {
              fontSize: ismobile_font ? this.fontfunciton() : "0.95rem",
              color: "#000",
              fontFamily: "Montserrat",
            },
            searchFieldStyle: {
              fontSize: "0.95rem",
            },
            filterCellStyle: {
              padding: "5px 16px",
              fontFamily: "Montserrat",
            },
          }}
          components={{
            Pagination: (props) => (
              <TablePagination
                {...props}
                labelRowsPerPage={
                  <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
                }
                labelDisplayedRows={(row) => (
                  <div style={{ fontSize: 12 }}>
                    {props.labelDisplayedRows(row)}
                  </div>
                )}
                SelectProps={{
                  style: {
                    fontSize: 12,
                  },
                }}
              />
            ),
          }}
        />
      </div>
    );
  };

  formatPayrollToNumber = (payroll) => {
    // Remove '$' and ',' and convert to number
    return payroll ? parseFloat(payroll.replace(/[^\d.-]/g, "")) : 0;
  };

  updateDisplay = async () => {
    let sessionData = await Auth.currentAuthenticatedUser();

    const makeNameReadable = (str) => {
      let _str = "";
      if (str) {
        _str = str
          .split("_")
          .map((e) => {
            return e[0].toUpperCase() + e.substr(1);
          })
          .join(" ");
      }
      return _str;
    };

    if (sessionData) {
      let loggedInGroups =
        sessionData?.signInUserSession?.accessToken?.payload?.[
        "cognito:groups"
        ];

      if (loggedInGroups) {
        this.setState({ loggedInGroups });
      }

      let userType = sessionData.attributes["custom:user_type"];
      console.log("userType: ", userType);
      let req_body = {
        first_time_loading: "true",
      };
      let rowDataList = [];
      let _submissionYesNoList = {};
      let _agentCommentsHistory = {};
      let last_key;

      if (userType === "peo") {
        let email = sessionData.attributes.email;
        let user_peo = email.split("@")[0];
        req_body[userType] =
          user_peo === "jgregory"
            ? "gregory"
            : user_peo === "galactichr"
              ? "galactic"
              : user_peo;
      }

      await new Promise((resolve, reject) => {
        axios
          .post(awsUrlBookUpload + "/api/getBookRollData/getAll", {})
          .then((res) => {
            let userDataList = [];
            let rowData;
            let step;

            let emailToNameMap = {
              "akshay@mobiux.in": "Akshay",
            };

            userDataList = res.data.list;
            last_key = res.data.old_key;

            // console.log(userDataList, "++++++++++++++++++++++++++++");
            for (let userDataRow in userDataList) {
              let userData = userDataList[userDataRow];
              let sortKeyPrepare = [];
              try {
                if (userData.status === "quote_generated") {
                  step = "Quote Generated";
                } else if (userData.status === "company_profile") {
                  step = "Underwriting Page";
                } else {
                  step = "-";
                }

                let payroll = 0;

                payroll = userData.total_payroll || payroll;

                let modifiedBy;

                if (
                  userData.modifiedBy &&
                  userData.modifiedBy in emailToNameMap
                ) {
                  modifiedBy =
                    emailToNameMap[userData.modifiedBy.trim().toLowerCase()];
                } else {
                  modifiedBy = userData.modifiedBy;
                }

                for (let key of userData?.address || []) {
                  sortKeyPrepare.push(
                    `${userData?.quoteData?.date}@${userData?.quoteData?.uuid}_${userData?.peoDetails?.selectedPeo}+${key}`
                  );
                }

                //  Process to get Governing class code.

                let governingClassCode;
                let govClassCode = [];
                let maxPayroll = -Infinity;
                let govccstate;
                const childrenLoc = userData.childrenLoc;

                // Loop through each location
                if (childrenLoc) {
                  Object.values(childrenLoc).map((location) => {
                    // Check if classCodesInfo property exists and has values
                    if (location.classCodesInfo) {
                      // Loop through classCodesInfo to get payroll details
                      Object.values(location.classCodesInfo).map(
                        (classCode) => {
                          if (classCode) {
                            let classCodeobj = {
                              payroll: this.formatPayrollToNumber(
                                classCode.payroll.value
                              ),
                              classCode: classCode.classCodeDescription?.value?.split(
                                ":"
                              )[0],
                              govccstate: location.state,
                            };
                            govClassCode.push(classCodeobj);
                          }
                        }
                      );
                    }
                  });
                }
                govClassCode.forEach((item) => {
                  if (item.payroll > maxPayroll) {
                    maxPayroll = item.payroll;
                    governingClassCode = item.classCode;
                    govccstate = item.govccstate.value;
                  }
                });

                rowData = {
                  step,
                  uploadTimestamp: userData.uploadTimestamp
                    ? moment(userData.uploadTimestamp, "x").format("M/D/YY")
                    : "-",
                  effectiveDate: userData?.companyProfile?.effectiveDate?.value
                    ? moment(
                      userData?.companyProfile?.effectiveDate?.value
                    ).format("M/D/YY")
                    : "-",
                  phoneNumber: userData?.companyProfile?.phoneNumber?.value,
                  contactName:
                    this.camelCase(userData?.companyProfile?.firstName?.value) +
                    " " +
                    this.camelCase(userData?.companyProfile?.lastName?.value),
                  companyName:
                    userData?.companyProfile?.companyName?.value || "-",
                  fein: userData?.companyProfile?.fein?.value,
                  modifiedBy: modifiedBy || "-",
                  childrenLoc: userData?.childrenLoc,
                  payroll: payroll?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                  }),
                  companyProfile: userData?.companyProfile,
                  state:
                    userData?.companyProfile?.state1?.value?.toUpperCase() ||
                    "-",
                  emodStatesData: userData?.emodStatesData,
                  uw_ques: userData?.uw_ques,
                  user_email_id: userData?.user_email_id,
                  status: userData?.status,
                  quoteData: userData?.quoteData,
                  address: userData?.address,
                  status: userData?.status,
                  riskProfile: userData?.riskProfile,
                  peoDetails: userData?.peoDetails,
                  carrierList: userData?.carrierList,
                  amtrustAccountDetails: userData?.amtrustAccountDetails,
                  amtrustAccountDetailsCopy:
                    userData?.amtrustAccountDetailsCopy,
                  quoteSubmitted: userData?.quoteSubmitted,
                  uw_comments: userData?.uw_comments
                    ? typeof userData?.uw_comments === "string"
                      ? [userData?.uw_comments]
                      : userData?.uw_comments
                    : [""],
                  agent_comments: userData?.agent_comments
                    ? typeof userData.agent_comments === "string"
                      ? [userData?.agent_comments]
                      : userData?.agent_comments
                    : [""],
                  uwStatus: userData?.uw_status,
                  uuidCarriers: sortKeyPrepare,
                  govClassCode: governingClassCode,
                  govCCState: govccstate,
                  selectedPeo: userData?.peoDetails?.selectedPeo,
                  salesforceData: userData?.salesforceData,
                  selectedPeoToDisplay: this.selectedPeoFormat(
                    userData?.peoDetails?.selectedPeo
                  ),
                  fileList: userData?.fileList ? userData?.fileList : [],
                  selectedPeoToDisplay: this.selectedPeoFormat(
                    userData?.peoDetails?.selectedPeo
                  ),
                  real_submission: userData.real_submission || "false",
                  status_info_rows: [],
                };

                let _status_info = userData?.status_info || {};

                try {
                  for (let status in _status_info) {
                    if (status === "underwriting_page_status") {
                      for (let carr in _status_info[status]) {
                        for (let sts in _status_info[status][carr]) {
                          rowData.status_info_rows.push({
                            status:
                              makeNameReadable(sts) +
                              " (with carrier: " +
                              (carrierMapping[carr] || carr) +
                              ")",
                            timestamp: moment(
                              _status_info[status][carr][sts].timestamp,
                              "x"
                            ).format("MM/DD/YYYY HH:mm:ss"),
                          });
                        }
                      }
                    } else {
                      rowData.status_info_rows.push({
                        status: makeNameReadable(status),
                        timestamp: moment(
                          _status_info[status].timestamp,
                          "x"
                        ).format("MM/DD/YYYY HH:mm:ss"),
                      });
                    }
                  }
                } catch (error) {
                  console.log(error);
                }

                rowData.status_info_rows.sort((a, b) =>
                  a.timestamp < b.timestamp
                    ? 1
                    : a.timestamp > b.timestamp
                      ? -1
                      : 0
                );

                createCommentForDownload(rowData, "uw_comments");
                createCommentForDownload(rowData, "agent_comments");

                _submissionYesNoList[rowData.user_email_id] =
                  userData.real_submission === "true" ? true : false;

                createSubmissionForDownload(rowData, "real_submission");

                _agentCommentsHistory[
                  rowData.user_email_id
                ] = userData?.agent_comments
                    ? typeof userData.agent_comments === "string"
                      ? [userData?.agent_comments]
                      : userData?.agent_comments
                    : [""];
                if (rowData.companyName === "-") continue;
                rowDataList.push(rowData);
              } catch (error) {
                console.log("error in: ", userData, error);
              }
            }

            this.setState((prevstate) => {
              let submissionYesNoList = JSON.parse(
                JSON.stringify(prevstate.submissionYesNoList)
              );
              let agentCommentsHistory = JSON.parse(
                JSON.stringify(prevstate.agentCommentsHistory)
              );
              return {
                rowDataList,
                isLoading: false,
                submissionYesNoList: {
                  ..._submissionYesNoList,
                  ...submissionYesNoList,
                },
                agentCommentsHistory: {
                  ..._agentCommentsHistory,
                  ...agentCommentsHistory,
                },
              };
            });
            resolve();
          })
          .catch((error) => {
            console.log("error", error);
            reject();
          });
      });

      // do {
      //   if (last_key) {
      //     req_body["lastEvaluatedKey"] = last_key;
      //   }

      //   delete req_body["first_time_loading"];

      //   await new Promise((resolve, reject) => {
      //     axios
      //       .post(awsUrl2 + "/api/userTableData/getAll", req_body)
      //       .then((res) => {
      //         console.log("res: ", res);
      //         let userDataList = [];
      //         let rowData;
      //         let step;

      //         let emailToNameMap = {
      //           "akshay@mobiux.in": "Akshay",
      //         };

      //         userDataList = res.data.list;
      //         last_key = res.data.old_key;

      //         // console.log(userDataList, "++++++++++++++++++++++++++++");

      //         for (let userDataRow in userDataList) {
      //           let userData = userDataList[userDataRow];
      //           let sortKeyPrepare = [];
      //           try {
      //             if (userData.status === "quote_generated") {
      //               step = "Quote Generated";
      //             } else if (userData.status === "company_profile") {
      //               step = "Underwriting Page";
      //             } else {
      //               step = "-";
      //             }

      //             // Calculating total Payroll

      //             let payroll = 0;
      //             let classcodeInfo;
      //             let sum = 0;

      //             let childloc = userData.childrenLoc;
      //             // console.log(userData)

      //             for (let userDataRow in childloc) {
      //               let userChildlocData = childloc[userDataRow];
      //               // console.log(userChildlocData);
      //               classcodeInfo = userChildlocData.classCodesInfo;
      //               classcodeInfo = Object.entries(classcodeInfo);
      //               // console.log(classcodeInfo)
      //               for (let i = 0; i < classcodeInfo.length; i++) {
      //                 let paydata = classcodeInfo[i];
      //                 const stringAmount = paydata[1]?.payroll?.value;
      //                 if (!stringAmount) continue;
      //                 const numberAmount = parseFloat(
      //                   stringAmount.replace(/[^\d.-]/g, "")
      //                 );
      //                 sum = sum + numberAmount;
      //               }
      //               // console.log(payroll)
      //             }
      //             payroll = sum;

      //             let modifiedBy;

      //             if (
      //               userData.modifiedBy &&
      //               userData.modifiedBy in emailToNameMap
      //             ) {
      //               modifiedBy =
      //                 emailToNameMap[userData.modifiedBy.trim().toLowerCase()];
      //             } else {
      //               modifiedBy = userData.modifiedBy;
      //             }

      //             for (let key of userData?.address || []) {
      //               sortKeyPrepare.push(
      //                 `${userData?.quoteData?.date}@${userData?.quoteData?.uuid}_${userData?.peoDetails?.selectedPeo}+${key}`
      //               );
      //             }

      //             // Calculating Highest payroll and finding Governing class code

      //             let governingClassCode;
      //             let govClassCode = [];
      //             let maxPayroll = -Infinity;
      //             let govccstate;
      //             const childrenLoc = userData.childrenLoc;

      //             if (childrenLoc) {
      //               Object.values(childrenLoc).map((location) => {
      //                 // Check if classCodesInfo property exists and has values
      //                 if (location.classCodesInfo) {
      //                   // Loop through classCodesInfo to get payroll details
      //                   Object.values(location.classCodesInfo).map(
      //                     (classCode) => {
      //                       if (classCode) {
      //                         let classCodeobj = {
      //                           payroll: this.formatPayrollToNumber(
      //                             classCode.payroll.value
      //                           ),
      //                           classCode: classCode.classCodeDescription?.value?.split(
      //                             ":"
      //                           )[0],
      //                           govccstate: location.state,
      //                         };
      //                         govClassCode.push(classCodeobj);
      //                       }
      //                     }
      //                   );
      //                 }
      //               });
      //             }
      //             govClassCode.forEach((item) => {
      //               if (item.payroll > maxPayroll) {
      //                 maxPayroll = item.payroll;
      //                 governingClassCode = item.classCode;
      //                 govccstate = item.govccstate.value;
      //               }
      //             });

      //             rowData = {
      //               step,
      //               uploadTimestamp: userData.uploadTimestamp
      //                 ? moment(userData.uploadTimestamp, "x").format("M/D/YY")
      //                 : "-",
      //               effectiveDate: userData?.companyProfile?.effectiveDate
      //                 ?.value
      //                 ? moment(
      //                     userData?.companyProfile?.effectiveDate?.value
      //                   ).format("M/D/YY")
      //                 : "-",
      //               phoneNumber: userData?.companyProfile?.phoneNumber?.value,
      //               contactName:
      //                 this.camelCase(
      //                   userData?.companyProfile?.firstName?.value
      //                 ) +
      //                 " " +
      //                 this.camelCase(userData?.companyProfile?.lastName?.value),
      //               companyName:
      //                 userData?.companyProfile?.companyName?.value || "-",
      //               fein: userData?.companyProfile?.fein?.value,
      //               modifiedBy: modifiedBy || "-",
      //               childrenLoc: userData?.childrenLoc,
      //               payroll: payroll?.toLocaleString("en-US", {
      //                 style: "currency",
      //                 currency: "USD",
      //                 minimumFractionDigits: 0,
      //               }),
      //               companyProfile: userData?.companyProfile,
      //               state:
      //                 userData?.companyProfile?.state1?.value?.toUpperCase() ||
      //                 "-",
      //               emodStatesData: userData?.emodStatesData,
      //               uw_ques: userData?.uw_ques,
      //               user_email_id: userData?.user_email_id,
      //               status: userData?.status,
      //               quoteData: userData?.quoteData,
      //               address: userData?.address,
      //               status: userData?.status,
      //               riskProfile: userData?.riskProfile,
      //               peoDetails: userData?.peoDetails,
      //               carrierList: userData?.carrierList,
      //               amtrustAccountDetails: userData?.amtrustAccountDetails,
      //               quoteSubmitted: userData?.quoteSubmitted,
      //               uw_comments: userData?.uw_comments
      //                 ? typeof userData?.uw_comments === "string"
      //                   ? [userData?.uw_comments]
      //                   : userData?.uw_comments
      //                 : [""],
      //               agent_comments: userData?.agent_comments
      //                 ? typeof userData.agent_comments === "string"
      //                   ? [userData?.agent_comments]
      //                   : userData?.agent_comments
      //                 : [""],
      //               uwStatus: userData?.uw_status,
      //               uuidCarriers: sortKeyPrepare,
      //               govClassCode: governingClassCode,
      //               govCCState: govccstate,
      //               selectedPeo: userData?.peoDetails?.selectedPeo,
      //               selectedPeoToDisplay: this.selectedPeoFormat(
      //                 userData?.peoDetails?.selectedPeo
      //               ),
      //               fileList: userData?.fileList ? userData?.fileList : [],
      //               selectedPeoToDisplay: this.selectedPeoFormat(
      //                 userData?.peoDetails?.selectedPeo
      //               ),
      //               real_submission: userData.real_submission || "false",
      //               status_info_rows: [],
      //             };

      //             let _status_info = userData?.status_info || {};

      //             try {
      //               for (let status in _status_info) {
      //                 if (status === "underwriting_page_status") {
      //                   for (let carr in _status_info[status]) {
      //                     for (let sts in _status_info[status][carr]) {
      //                       rowData.status_info_rows.push({
      //                         status:
      //                           makeNameReadable(sts) +
      //                           " (with carrier: " +
      //                           (carrierMapping[carr] || carr) +
      //                           ")",
      //                         timestamp: moment(
      //                           _status_info[status][carr][sts].timestamp,
      //                           "x"
      //                         ).format("MM/DD/YYYY HH:mm:ss"),
      //                       });
      //                     }
      //                   }
      //                 } else {
      //                   rowData.status_info_rows.push({
      //                     status: makeNameReadable(status),
      //                     timestamp: moment(
      //                       _status_info[status].timestamp,
      //                       "x"
      //                     ).format("MM/DD/YYYY HH:mm:ss"),
      //                   });
      //                 }
      //               }
      //             } catch (error) {
      //               console.log(error);
      //             }

      //             rowData.status_info_rows.sort((a, b) =>
      //               a.timestamp < b.timestamp
      //                 ? 1
      //                 : a.timestamp > b.timestamp
      //                 ? -1
      //                 : 0
      //             );

      //             createCommentForDownload(rowData, "uw_comments");
      //             createCommentForDownload(rowData, "agent_comments");

      //             _submissionYesNoList[rowData.user_email_id] =
      //               userData.real_submission === "true" ? true : false;

      //             createSubmissionForDownload(rowData, "real_submission");
      //             _agentCommentsHistory[
      //               rowData.user_email_id
      //             ] = userData?.agent_comments
      //               ? typeof userData.agent_comments === "string"
      //                 ? [userData?.agent_comments]
      //                 : userData?.agent_comments
      //               : [""];
      //             if (rowData.companyName === "-") continue;
      //             rowDataList.push(rowData);
      //           } catch (error) {
      //             console.log("error in: ", userData, error);
      //           }
      //         }

      //         this.setState((prevstate) => {
      //           let submissionYesNoList = JSON.parse(
      //             JSON.stringify(prevstate.submissionYesNoList)
      //           );
      //           let agentCommentsHistory = JSON.parse(
      //             JSON.stringify(prevstate.agentCommentsHistory)
      //           );
      //           return {
      //             rowDataList,
      //             isLoading: false,
      //             submissionYesNoList: {
      //               ..._submissionYesNoList,
      //               ...submissionYesNoList,
      //             },
      //             agentCommentsHistory: {
      //               ..._agentCommentsHistory,
      //               ...agentCommentsHistory,
      //             },
      //           };
      //         });
      //         resolve();
      //       })
      //       .catch((error) => {
      //         console.log("error", error);
      //         reject();
      //       });
      //   });
      // } while (last_key);

      if (!last_key) {
        this.setState({ dataLoading: false });
      }
    }
  };

  updatePopUpData = (popupData) => {
    this.setState({ popupData });
  };

  toastSuccess = (text) =>
    toast.success(text, {
      duration: 3000,
    });

  toastError = (text) =>
    toast.error(text, {
      duration: 3000,
    });

  getSalesforcePayrollInfo = (childrenloc) => {
    let payrollInfo = [];
    let totalPayroll = 0;
    try {
      for (let locationIndex in childrenloc) {
        let state = childrenloc[locationIndex].state.value;
        for (let classCodeIndex in childrenloc[locationIndex].classCodesInfo) {
          let { classCodeDescription, payroll } = childrenloc[
            locationIndex
          ].classCodesInfo[classCodeIndex];
          let splitArray = classCodeDescription.value.split(":");

          let classCode = splitArray[0];
          let description = splitArray
            .slice(1)
            .join(":")
            .trim();

          payroll = numeral(payroll.value).value();
          totalPayroll += payroll;

          payrollInfo.push({
            classCode,
            description,
            state,
            payroll: String(payroll),
          });
        }
      }
    } catch (error) {
      console.log(error);
      payrollInfo = [];
      totalPayroll = 0;
    }

    return { payrollInfo, totalPayroll: String(totalPayroll) };
  };
  populateUserTableWithFileList = async (populateFlag) => {
    const fileList = [];
    const uwFileList = [];
    if (!populateFlag) {
      try {
        axios
          .post(awsUrl + "/api/underWriterData/getAll", {})
          .then(async (res) => {
            let dataFromAPI = res.data;
            for (let item in dataFromAPI)
              for (let item_item in dataFromAPI[item]) {
                if (
                  dataFromAPI[item][item_item]?.["fileList"] ||
                  dataFromAPI[item][item_item]?.["uwFileList"]
                ) {
                  for (let fileIndex in dataFromAPI[item][item_item]?.[
                    "fileList"
                  ]) {
                    if (
                      !fileList.includes(
                        dataFromAPI[item][item_item]["fileList"][fileIndex][
                        "key"
                        ]
                      )
                    )
                      fileList.push(
                        dataFromAPI[item][item_item]["fileList"][fileIndex][
                        "key"
                        ]
                      );
                  }
                  for (let fileIndex in dataFromAPI[item][item_item]?.[
                    "uwFileList"
                  ]) {
                    if (
                      !uwFileList.includes(
                        dataFromAPI[item][item_item]["uwFileList"][fileIndex][
                        "key"
                        ]
                      )
                    )
                      uwFileList.push(
                        dataFromAPI[item][item_item]["uwFileList"][fileIndex][
                        "key"
                        ]
                      );
                  }
                }
              }
            const fileListCopy = {};
            fileList.forEach((item) => {
              const key = item.split("/")[0];
              if (Object.keys(fileListCopy).includes(key))
                fileListCopy[key].push(item);
              else {
                fileListCopy[key] = [];
                fileListCopy[key].push(item);
              }
            });
            uwFileList.forEach((item) => {
              const key = item.split("/")[0];
              if (Object.keys(fileListCopy).includes(key))
                fileListCopy[key].push(item);
              else {
                fileListCopy[key] = [];
                fileListCopy[key].push(item);
              }
            });
            for (let item in fileListCopy) {
              const userFileDetail = {
                user_email_id: item,
                fileList: fileListCopy[item],
              };
              await axios
                .post(awsUrl2 + "/api/userTableData/update", userFileDetail)
                .then((res2) => { })
                .catch((err) => {
                  console.log("Error:", err);
                });
              console.log("inserted successfully");
              // break;
            }
          });
      } catch (err) {
        console.log(err);
      }
      this.setState({ populateFlag: true });
    }
  };

  sendToSalesforce = async (rowData) => {
    try {
      let {
        companyName,
        descriptionOfOperations,
        entityType,
        fein,
        phoneNumber,
        yearsInBusiness,
        effectiveDate,
        street1,
        street2,
        cityName1,
        cityName2,
        state1,
        state2,
        zipCode1,
        zipCode2,
      } = rowData?.companyProfile || {};

      let { accountId, opportunityId } = rowData?.salesforceData || {};

      let { payrollInfo, totalPayroll } = this.getSalesforcePayrollInfo(
        rowData?.childrenLoc
      );

      let data = {
        companyName: companyName?.value?.trim() || "",
        description: descriptionOfOperations?.value?.trim() || "",
        entityType: entityType?.value?.trim() || "",
        fein: fein?.value?.trim() || "",
        phoneNumber: phoneNumber?.value?.trim() || "",
        yearsInBusiness: yearsInBusiness?.value?.trim() || "",
        opportunityId,
        accountId,
        totalPayroll,
        effectiveDate: moment
          .utc(effectiveDate.value)
          .local()
          .format("M/D/YYYY"),
        billingStreet: street2?.value?.trim(),
        billingCity: cityName2?.value?.trim(),
        billingState: state2?.value?.trim()?.toUpperCase(),
        billingPostalCode: zipCode2?.value?.trim(),
        billingCountry: "USA",
        shippingStreet: street1?.value?.trim(),
        shippingCity: cityName1?.value?.trim(),
        shippingState: state1?.value?.trim()?.toUpperCase(),
        shippingPostalCode: zipCode1?.value?.trim(),
        shippingCountry: "USA",
        payrollInfo,
      };
      console.log(data);

      let response = await axios.put(awsUrl2 + "/api/sendToSalesforce", data);
      console.log("++response", response);
      this.toastSuccess("Synced successfully");
    } catch (error) {
      console.log(error);
      this.toastError("Error while syncing");
    }
  };

  render() {
    let { openModal, modalBody, agentButton, handleCloseModal } = this.props;
    // !this.state.populateFlag && this.populateUserTableWithFileList();
    let { showModel, modalTitle, modalContent } = this.state.modalData;
    return (
      <div>
        {this.state.dataLoading ? (
          <div style={{ marginLeft: "50%" }}>
            <RotatingLines
              visible={true}
              height="40"
              width="40"
              color="black"
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
            <b>Data Loading.....</b>
          </div>
        ) : (
          <div style={{ marginLeft: "50%" }}></div>
        )}

        <div ref={this.myRef}>{this.setTableData()}</div>
        {agentButton ? (
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>
              <p style={{ textAlign: "center" }}>
                {(modalBody.length > 0 &&
                  modalBody?.[0] !== "" &&
                  modalBody?.[0]?.comment !== "") ||
                  modalBody?.[0]?.previousComment
                  ? "Comment History"
                  : "No Comment History"}
              </p>
              <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                style={{ position: "absolute", right: "5px", top: "8px" }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {modalBody.length > 0 &&
                modalBody[0] !== "" &&
                modalBody.map((item, index) => {
                  let date = moment(item.commentTimestamp)
                    .format("M/D/YY h:mm A")
                    .split(" ")[0];
                  let time = moment(item.commentTimestamp)
                    .format("M/D/YY h:mm A")
                    .split(" ")[1];
                  if (item.comment !== "") {
                    if (item.commentTimestamp || item.email || item) {
                      return (
                        <div>
                          <div>
                            <span>
                              <b>{date}</b>
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <b>{time}</b>
                            </span>
                            <span>{item.email ? "- " : ""}</span>
                            <span>
                              <b>{item.email?.split("@")[0]}</b>
                            </span>
                          </div>
                          <div>
                            <p>
                              {item?.comment
                                ? item.comment
                                : modalBody[index + 1]?.comment
                                  ? modalBody[index + 1].comment
                                  : item}
                            </p>
                          </div>
                          <br />
                        </div>
                      );
                    }
                  } else {
                    return;
                  }
                })}
            </DialogContent>
          </Dialog>
        ) : (
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>
              <p style={{ textAlign: "center" }}>
                {(modalBody.length > 0 &&
                  modalBody?.[0] !== "" &&
                  modalBody?.[0]?.comment !== "") ||
                  modalBody?.[0]?.previousComment
                  ? "Comment History"
                  : "No Comment History"}
              </p>
              <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                style={{ position: "absolute", right: "5px", top: "8px" }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {modalBody.length > 0 &&
                modalBody[0] !== "" &&
                modalBody.map((item, index) => {
                  let date = moment(item.commentTimestamp)
                    .format("M/D/YY h:mm A")
                    .split(" ")[0];
                  let time = moment(item.commentTimestamp)
                    .format("M/D/YY h:mm A")
                    .split(" ")[1];
                  if (item.comment !== "") {
                    if (item.commentTimestamp || item.email || item) {
                      return (
                        <div>
                          <div>
                            <span>
                              <b>{date}</b>
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <b>{time}</b>
                            </span>
                            <span>{item.email ? "- " : ""}</span>
                            <span>
                              <b>{item.email?.split("@")[0]}</b>
                            </span>
                          </div>
                          <div>
                            <p>
                              {item?.comment
                                ? item.comment
                                : modalBody[index + 1]?.comment
                                  ? modalBody[index + 1].comment
                                  : item}
                            </p>
                          </div>
                          <br />
                        </div>
                      );
                    }
                  } else {
                    return;
                  }
                })}
            </DialogContent>
          </Dialog>
        )}
        <Modal
          show={showModel}
          onHide={() => {
            this.setModalData({
              showModel: false,
              modalTitle: null,
              modalContent: null,
            });
          }}
          dialogClassName="model-download modal-dialog-centered"
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalContent}</Modal.Body>
        </Modal>
        <Toaster />
        {this.state.popupData.show && (
          <Popup
            popupData={this.state.popupData}
            updatePopUpData={this.updatePopUpData}
          />
        )}
      </div>
    );
  }
}

export default DashboardLibertate;
