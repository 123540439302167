let mode;

if (process.env.REACT_APP_MODE) {
  mode = process.env.REACT_APP_MODE;
} else {
  mode = "dev";
}
console.log("env", process.env);

var localURL = "http://localhost:3000/dev";

export var demoAuthUrl =
  "https://toyg4ps5yl.execute-api.us-east-1.amazonaws.com/dev";

// server_coreSetURL1;
export var awsUrl =
  "https://se6qso6svb.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta = "https://bcq7kpcgqg.execute-api.us-east-1.amazonaws.com/beta";
var awsUrlProd = "https://4wyxok15pi.execute-api.us-east-1.amazonaws.com/prod";
export var awsAcordUpload =
  "https://5swuplkv50.execute-api.us-east-1.amazonaws.com/dev";

// server_coreSetURL2;
export var awsUrl2 =
  "https://z4netv2dvj.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta2 = "https://171a3gbrri.execute-api.us-east-1.amazonaws.com/beta";
var awsUrlProd2 = "https://exr72pihm9.execute-api.us-east-1.amazonaws.com/prod";

export var awsUrlBookUpload =
  "https://trhjf8s0w4.execute-api.us-east-1.amazonaws.com/dev";

// dashboard URL
export var dashboardUrl =
  "https://dmuwt3vd0e.execute-api.us-east-1.amazonaws.com/dev";
var dashboardUrlProd = "";

if (mode === "local1") {
  awsUrl = localURL;
  awsAcordUpload = localURL;
} else if (mode === "local2") {
  awsUrl2 = localURL;
  awsAcordUpload = localURL;
} else if (mode === "locald") {
  dashboardUrl = localURL;
} else if (mode === "prod") {
  awsUrl = awsUrlProd;
  awsUrl2 = awsUrlProd2;
  dashboardUrl = dashboardUrlProd;
} else if (mode === "beta") {
  awsUrl = awsUrlBeta;
  awsUrl2 = awsUrlBeta2;
  dashboardUrl = dashboardUrlProd;
}

export const versionNumber = "1.0.4 (m)";
