import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import UnderWriterFlow from "./component/pages/UnderWriterFlow";
import ProgramDetails from "./component/pages/ProgramDetails";
import NewQuote from "./newcomponent/NewQuote";
import Login from "./component/pages/Login";
import PanelUW from "./component/pages/PanelUW";
import Tabs from "./component/pages/Tabs";
import DashboardLibertate from "./component/pages/DashboardLibertate";
import DashboardRater from "./component/pages/DashboardRater";
import AntiPrivateRoute from "./component/common/AntiPrivateRoute";
import PrivateGroupRoute from "./component/common/PrivateGroupRoute";
import { NewRates } from "./newcomponent/NewRates/NewRates";
import DataUpload from "./component/pages/DataUpload";
import RaterUpload from "./component/pages/RaterUpload";
import RiskProfile from "./component/subcompo/sections-quote/RiskProfile";
import DemoRouteHandler from "./component/pages/DemoRouteHandler";
import { LibertateContextProvider } from "./context/ContextProvider";
import AmtrustUnderQues from "./component/pages/AmtrustUW";
import BookRollUpload from "./component/book-roll/upload/BookRollUpload";
import BookRollDashboard from "./component/pages/BookRollDashboard";

class App extends Component {
  render() {
    return (
      <LibertateContextProvider>
        <Router>
          <Switch>
            {/* <PrivateGroupRoute
            allowedGroup={process.env.REACT_APP_ADMIN_GROUP}
            exact
            path="/AdminDashboard"
            component={Tabs}
          />
          <PrivateGroupRoute
            allowedGroup={process.env.REACT_APP_SALES_GROUP}
            exact
            path="/SalesDashboard"
            component={DashboardSales}
          />
          <AntiPrivateRoute path="/Login" component={Login} />
          <PrivateGroupRoute
            allowedGroup={process.env.REACT_APP_CARRIER_GROUP}
            exact
            path="/PanelUW"
            component={PanelUW}
          />
          <Route path="/program-details" component={ProgramDetails} /> */}
            <PrivateGroupRoute
              allowedGroups={[process.env.REACT_APP_BOOK_ROLL]}
              exact
              path="/bookupload"
              component={BookRollUpload}
            />
            <PrivateGroupRoute
              allowedGroups={[process.env.REACT_APP_BOOK_ROLL]}
              exact
              path="/bookDashboard"
              component={BookRollDashboard}
            />
            <Route path="/demo" component={DemoRouteHandler} />
            <PrivateGroupRoute
              allowedGroups={[process.env.REACT_APP_RATER_GROUP]}
              exact
              path="/RaterUpload"
              component={RaterUpload}
            />
            <PrivateGroupRoute
              allowedGroups={[
                process.env.REACT_APP_UPLOAD_COMPANY,
                process.env.REACT_APP_UPLOAD_RATER,
              ]}
              exact
              path="/DataUpload"
              component={DataUpload}
            />
            <PrivateGroupRoute
              allowedGroups={[process.env.REACT_APP_DASHBOARD_GROUP]}
              exact
              path="/Dashboard"
              component={DashboardLibertate}
            />
            <PrivateGroupRoute
              allowedGroups={[process.env.REACT_APP_GET_QUOTE_GROUP]}
              exact
              path="/Quote"
              component={NewQuote}
            />
            <PrivateGroupRoute
              allowedGroups={[process.env.REACT_APP_RATER_GROUP]}
              exact
              path="/Rates"
              component={NewRates}
            />
            <Route path="/riskprofile" component={RiskProfile} />
            <PrivateGroupRoute
              allowedGroups={[process.env.REACT_APP_UW_GROUPS]}
              exact
              path="/UwPortal"
              component={UnderWriterFlow}
            />
            <PrivateGroupRoute
              allowedGroups={[process.env.REACT_APP_DASHBOARD_GROUP]}
              exact
              path="/RaterDashboard"
              component={DashboardRater}
            />
            <PrivateGroupRoute
              allowedGroups={[process.env.REACT_APP_UW_GROUPS]}
              exact
              path="/amtrustUW"
              component={AmtrustUnderQues}
            />
            <Route path="/" component={Login} />
          </Switch>
        </Router>
      </LibertateContextProvider>
    );
  }
}

export default App;
