import React from "react";
import CustomDropZone from "./CustomDropZone";
import axios from "axios";
import { awsUrl2, awsUrlBookUpload } from "../../../../config";
import LoadingOverlay from "react-loading-overlay";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import styles from "../BookRollUpload.module.css";
const moment = require("moment");
const { carrierPeoProgramList } = require("../../../../utils/carrierList.json");

export default class DropZones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: {},
      uploadingInProgress: false,
      uploadMsg: undefined,
      errMsg: undefined,
      histFileList: {},
      histUploadingInProgress: false,
      histUploadMsg: undefined,
      histErrMsg: undefined,
      eppFileList: {},
      eppUploadingInProgress: false,
      eppUploadMsg: undefined,
      eppErrMsg: undefined,
      selectedTab: "profile",
      fileStatusTableData: [],
      intervalID: undefined,
    };
  }

  async componentDidMount() {
    try {
      this.fetchFilesData();
      let intervalID = setInterval(async () => {
        await this.fetchFilesData();
      }, 5000);
      this.setState({ intervalID });
    } catch (error) {
      console.log("error in re-calling the table API");
    }
  }

  componentWillUnmount() {
    try {
      clearInterval(this.state.intervalID);
    } catch (error) {
      console.log("error in unmount interval removal");
    }
  }

  updateFileList = (newFile) => {
    let { fileList, histFileList, eppFileList } = this.state;
    let files;

    files = fileList;

    if (files[newFile.name] && files[newFile.name] === "#empty") {
      this.setState({ errMsg: "File already exist", uploadMsg: undefined });

      return;
    }
    files[newFile.name] = newFile;

    fileList = files;

    this.setState({
      fileList,
      histFileList,
      eppFileList,
      errMsg: undefined,
      uploadMsg: undefined,
      histErrMsg: undefined,
      histUploadMsg: undefined,
      eppErrMsg: undefined,
      eppUploadMsg: undefined,
    });
  };

  removeFile = (fileName) => {
    let { fileList, histFileList, eppFileList } = this.state;
    let files;

    files = fileList;

    delete files[fileName];

    fileList = files;

    this.setState({ fileList, histFileList, eppFileList });
  };

  uploadFile = async (fileData, postDataS3) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrlBookUpload + "/api/getBookUploadSignedUrl", postDataS3)
        .then((response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.lossSignedURL;
          let options = { header: { "Content-Type": postDataS3.fileType } };
          axios
            .put(lossSignedURL, fileData, options)
            .then((result) => {
              console.log("file upload res: ", result);
              resolve(returnData.url);
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject();
            });
        })
        .catch((error) => {
          console.log("error in uploadLossForm ", error);
          reject();
        });
    });
  };

  updateBookRollTable = (dataToSend) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrlBookUpload + "/api/updateBookRollTable", dataToSend)
        .then((res) => {
          this.setState({
            uploadMsg: "files uploaded",
            errMsg: undefined,
          });

          resolve();
        })
        .catch((error) => {
          console.log("error in API-updateBookRollTable", error);
          reject();
        });
    });
  };

  handleUpload = async () => {
    try {
      let client = "libertate";
      let { fileList } = this.state;
      let isLoggedIn = await Auth.currentAuthenticatedUser();
      let userEmail = isLoggedIn?.attributes?.email;
      let date = moment.utc().format("x");
      let peoDetails = JSON.parse(sessionStorage.getItem("peoDetails"));
      let carrierList = JSON.parse(sessionStorage.getItem("carrierList"));

      // prepare the carrier list
      let carriersListToSend = [];
      let userSelectedCarriers = [];

      for (let obj of carrierList) {
        userSelectedCarriers.push(obj.value);
      }

      for (let carrier of carrierPeoProgramList) {
        if (
          userSelectedCarriers.includes(carrier.carrier) &&
          (peoDetails.selectedPeo === carrier.peo ||
            (peoDetails.selectedPeo === "demo" && carrier.peo === "gms"))
        )
          carriersListToSend.push(carrier);
      }

      let files;

      files = fileList;
      this.setState({ uploadingInProgress: true });

      for (let currFileName in files) {
        if (currFileName && files[currFileName]) {
          let fileType = currFileName.split(".").reverse()[0];

          const postDataS3 = {
            fileName: currFileName,
            fileType,
            client: `${client}/${userEmail}`,
          };

          let url = await this.uploadFile(files[currFileName], postDataS3);

          await this.updateBookRollTable({
            uuid: uuidv4(),
            status: "NEW",
            filename: `${currFileName}`,
            timestamp: date,
            url,
            client,
            flag: `${client}/${userEmail}`,
            emailTo: userEmail,
            carrierPeoList: carriersListToSend,
          });

          await axios.post(
            awsUrlBookUpload + `/api/bookRollStatusTable/putData`,
            JSON.stringify({
              email: userEmail,
              client,
              date: date,
              key: `${client}/${userEmail}/${currFileName}`,
              status: "validating",
              folder: client,
            })
          );

          // await this.fetchFilesData();
          files[currFileName] = "#empty";
        }
      }

      this.setState({ uploadingInProgress: false, fileList: files });
    } catch (error) {
      console.log("error: ", error);

      this.setState({
        uploadingInProgress: false,
        uploadMsg: undefined,
        errMsg: "error in uploading",
      });
    }
  };

  validate = (file) => {};

  setSelectedState = (val) => {
    this.setState({ selectedTab: val });
  };

  fetchFilesData = async () => {
    let isLoggedIn = await Auth.currentAuthenticatedUser();
    let userEmail = isLoggedIn?.attributes?.email;
    let res = await axios.post(
      awsUrlBookUpload + `/api/bookRollStatusTable/getData`,
      JSON.stringify({
        email: userEmail,
        client: "libertate",
        uploadType: ["libertate"],
      })
    );

    this.setState({ fileStatusTableData: res.data?.libertate });
  };

  render() {
    let {
      fileList,
      uploadingInProgress,
      uploadMsg,
      errMsg,
      fileStatusTableData,
      selectedTab,
    } = this.state;
    return (
      <>
        <div className={styles.dropZonesContainer}>
          <LoadingOverlay active={uploadingInProgress} spinner>
            <CustomDropZone
              fileList={fileList}
              updateFileList={this.updateFileList}
              removeFile={this.removeFile}
              heading={"Upload File"}
              uploadMsg={uploadMsg}
              flag={"first"}
              downloadFileName={"book_roll_import_sample.xlsx"}
            />
            <DropButton
              uploadMsg={uploadMsg}
              errMsg={errMsg}
              handleUpload={this.handleUpload}
              uploadingInProgress={uploadingInProgress}
              flag={"first"}
            />
          </LoadingOverlay>
        </div>
        <FileStatusTable
          setSelectedState={this.setSelectedState}
          selectedTab={selectedTab}
          fileStatusTableData={fileStatusTableData}
        />
      </>
    );
  }
}

const DropButton = (props) => {
  return (
    <div className="d-flex flex-column">
      <pre
        className={
          "text-center text-capitalize " +
          (props.uploadMsg ? "text-success" : "text-danger")
        }
      >
        {props.uploadMsg || props.errMsg}
      </pre>
      <button
        className="btn upload-docs"
        style={{
          width: "100%",
          backgroundColor: "#1463AC",
          alignSelf: "center",
          color: "WHITE",
        }}
        onClick={() => props.handleUpload()}
        disabled={props.uploadingInProgress}
      >
        Upload
      </button>
    </div>
  );
};

const FileStatusTable = (props) => {
  return (
    <div className={styles.fileStatusTableContainer}>
      <h4>File Status</h4>
      <div className="tab-content" id="myTabContent">
        <TableForTab data={props.fileStatusTableData} />
      </div>
    </div>
  );
};

const TableForTab = (props) => {
  let rowsData = props.data
    ? props.data.map((row, indx) => {
        return (
          <tr>
            <th scope="row">{indx + 1}</th>
            <td>
              {moment
                .utc(Number(row.date))
                .utcOffset("-0500")
                .format("M/D/YYYY - hh:mm A")}
            </td>
            <td>{row.email_client.split("_")[0]}</td>
            <td>{row.key.split("/").reverse()[0]}</td>
            <td>{row.status}</td>
          </tr>
        );
      })
    : [];

  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Date</th>
            <th scope="col">Email</th>
            <th scope="col">File name</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>{rowsData}</tbody>
      </table>
    </div>
  );
};
