import React from "react";
import { useEffect, useState } from "react";
import numeral from "numeral";
import Select from "react-select";
import Clamp from "react-multiline-clamp";
import carrierList from "../../../utils/carrierList.json";
import { awsUrl } from "../../../config";
import { blobPdfFromBase64String } from "../../../utils/common";
import axios from "axios";
import SmallLoader from "../../common/SmallLoader";

const carrierListMapping = carrierList.carrierMapping;

const RiskProfile = (props) => {
  let commonPercentage = 100;
  const [data, setData] = useState({});
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [carrierDropdown, setCarrierDropdown] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState();
  const [selectedPremium, setSelectedPremium] = useState({
    value: "manual_premium",
    label: "Manual Premium",
  });
  const [premiumDropdown, setPremiumDropdown] = useState([
    {
      value: "manual_premium",
      label: "Manual Premium",
    },
  ]);

  useEffect(() => {
    const fetchRiskProfile = async () => {
      try {
        let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
        let user_id = sessionStorage.getItem("user_id");

        let { riskProfile, riskProfileId } = currProspect;
        if (riskProfile && JSON.stringify(riskProfile) !== "{}") {
          setData(riskProfile);
        } else if (riskProfileId) {
          // create an api - instead of direct url. secure s3 bucket - fetch status from userTable.
          let riskProfileUrl, status;
          setIsLoading(true);
          // Retry mechanism
          const maxRetries = 5;
          let attempts = 0;
          let riskProfileData;
          //retry with api call to fetch signed url.
          while (attempts < maxRetries) {
            try {
              let res = await axios.post(
                awsUrl + "/api/getRiskProfileSignedUrl",
                JSON.stringify({
                  user_id,
                })
              );
              status = res?.data?.status;

              riskProfileUrl = res?.data?.signedUrl || "";
              if (!status) {
                attempts = 5;
                setIsLoading(false);
                setMessage("Error fetching risk profile");
                return;
              }
              if (status === "Completed") {
                const response = await axios.get(riskProfileUrl);
                riskProfileData = response.data;
              }

              if (riskProfileData && JSON.stringify(riskProfileData) !== "{}") {
                setData(riskProfileData);
                setIsLoading(false);

                return; // Exit if successful
              }
            } catch (error) {
              console.error("Error fetching risk profile data:", error);
              setIsLoading(false);
            }

            attempts++;
            if (attempts < maxRetries) {
              // Wait for 5 seconds before the next attempt
              await new Promise((resolve) => setTimeout(resolve, 5000));
            } else {
              setMessage("Error fetching risk profile");
            }
          }

          if (!riskProfileData) {
            console.error(
              "Failed to fetch risk profile data after multiple attempts."
            );
          }
        }
      } catch (error) {
        console.error("Error in risk profile:", error);
      }
    };

    // Run fetchRiskProfile initially
    fetchRiskProfile();
  }, []); // Empty dependency array to run once on mount
  useEffect(() => {
    setCarrierList();
  }, [data]);

  const setCarrierList = () => {
    let carrierList = [];
    let carrierMap = {};
    let peoDetails = {};
    let selectedCarrierlist = [];
    let preparedCarrierlist = [];
    try {
      peoDetails = JSON.parse(sessionStorage.getItem("currProspect"))
        .peoDetails;

      let riskProfileList = JSON.parse(JSON.stringify(data));
      riskProfileList = Object.keys(riskProfileList);

      selectedCarrierlist = JSON.parse(sessionStorage.getItem("currProspect"))
        .carrierList?.value;

      for (let key of selectedCarrierlist) {
        if (riskProfileList.includes(key.value)) {
          preparedCarrierlist.push({ label: key.label, value: key.value });
        }
      }
    } catch (error) {}

    // for (let key in data) {
    //   carrierList.push({
    //     label:
    //       key && peoDetails.selectedPeo !== "demo"
    //         ? carrierListMapping[key]
    //         : key?.toUpperCase(),
    //     value: key,
    //   });
    // }

    // carrierList.sort((a, b) => (a.label > b.label ? 1 : -1));

    setCarrierDropdown(preparedCarrierlist);
  };

  const createTable = (table, allTableData) => {
    let rows = [];
    let tableDetailsMap = {
      eligibility: {
        tableDataKey: "eligibilityData",
        tableName: "Eligibility Profile",
        tableHeader: "Eligibility",
      },
      sic: {
        tableDataKey: "sicData",
        tableName: "SIC Group Profile",
        tableHeader: "Industry Classification",
      },
      hazard: {
        tableDataKey: "hazardData",
        tableName: "Best Hazard Profile",
        tableHeader: "Best Composite",
      },
      naics: {
        tableDataKey: "naicsData",
        tableName: "NAICS Group Profile",
        tableHeader: "Industry Classification",
      },
      state: {
        tableDataKey: "stateData",
        tableName: "State Summary",
        tableHeader: "States",
      },
    };

    if (table in tableDetailsMap) {
      let tableData = allTableData[tableDetailsMap[table].tableDataKey];
      tableData = sortTableData(tableData);
      for (let key in tableData) {
        rows.push(
          <tr key={table + "-" + key + "row"} className="table-rows">
            <td className="key" title={key}>
              <Clamp lines={1}>{key}</Clamp>
            </td>
            <td className="payroll">
              {numeral(tableData[key].payroll).format("$0,0")}
            </td>
            <td className="premium">
              {numeral(tableData[key].manualPremium).format("$0,0")}
            </td>
            <td className="mix-rate">
              {(tableData[key].mixRate * 100).toFixed(2)}%
            </td>
            <td className="wage-mix">
              {(tableData[key].wageMix * 100).toFixed(2)}%
            </td>
            <td className="premium-mix">
              {(tableData[key].premiumMix * 100).toFixed(2)}%
            </td>
          </tr>
        );
      }

      rows.push(
        <tr key={table + "-" + "total"} className="totals-row">
          <td className="Total-riskprofile">Total</td>
          <td>{numeral(allTableData.totalData.payroll).format("$0,0")}</td>
          <td>
            {numeral(allTableData.totalData.manualPremium).format("$0,0")}
          </td>
          <td>{(allTableData.totalData.mixRate * 100).toFixed(2)}%</td>
          <td>{commonPercentage.toFixed(2)}%</td>
          <td>{commonPercentage.toFixed(2)}%</td>
        </tr>
      );

      return (
        <div className="riskProfile-table-responsive">
          <table key={table + "-table"} className={`table ${table}-table`}>
            <tr className="table-header">
              <th rowSpan={rows.length + 1} className="table-name">
                {tableDetailsMap[table].tableName}
              </th>
              <th className="key-header">
                {tableDetailsMap[table].tableHeader}
              </th>
              <th className="payroll-header">Est. Payroll</th>
              <th className="premium-header">Man. Prem.</th>
              <th className="mix-rate-header">Mix Rate</th>
              <th className="wage-mix-header">Payroll Mix</th>
              <th className="premium-mix-header">Prem Mix</th>
            </tr>
            {rows}
          </table>
        </div>
      );
    }
  };

  const sortTableData = (tableData) => {
    let response = {};
    let sortList = [];
    try {
      for (let key in tableData) {
        sortList.push({
          key,
          ...tableData[key],
        });
      }
      sortList.sort((a, b) => a.manualPremium - b.manualPremium);
      for (let element of sortList) {
        let key = element.key;
        delete element.key;
        response[key] = element;
      }
    } catch (error) {
      console.log(error);
      response = tableData;
    }

    return response;
  };

  const sortTables = (carrierData) => {
    let response = {};
    let sortOrder = ["eligibility", "sic", "naics", "hazard", "state"];
    try {
      for (let key of sortOrder) {
        if (key in carrierData) {
          response[key] = carrierData[key];
        }
      }
    } catch (error) {
      console.log(error);
      response = carrierData;
    }

    return response;
  };

  const createAllTables = (carrier) => {
    let tables = [];
    if (carrier in data) {
      let carrierData = JSON.parse(JSON.stringify(data[carrier]));
      carrierData = sortTables(carrierData);
      for (let tableKey in carrierData) {
        tables.push(createTable(tableKey, carrierData[tableKey]));
        tables.push(<div key={tableKey + "spacing"} className="mt-5"></div>);
      }
    } else if (carrier) {
      tables = <div className="mt-4">No data for the selected carrier</div>;
    } else {
      tables = <div className="mt-4">Please select a carrier</div>;
    }
    return tables;
  };

  const downloadRiskProfile = () => {
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    if (
      data?.[selectedCarrier?.value || "#1256793"] &&
      currProspect?.companyProfile?.companyName?.value &&
      selectedCarrier?.label
    )
      axios
        .post(
          awsUrl + "/api/generateRiskProfile",
          JSON.stringify({
            data_to_print: data[selectedCarrier.value],
            comp_name: currProspect.companyProfile.companyName.value,
            carrier_name: selectedCarrier.label,
          })
        )
        .then((res) => {
          let pdf = res.data.data;
          let file = blobPdfFromBase64String(pdf);
          var fileURL = URL.createObjectURL(file);

          let blobUrl = fileURL;
          const link = document.createElement("a");

          // create a blobURI pointing to our Blob
          link.href = blobUrl;
          link.download = `RiskProfile_${selectedCarrier.label}`;

          // some browser needs the anchor to be in the doc
          document.body.append(link);
          link.click();

          try {
            link.remove();

            // in case the Blob uses a lot of memory
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
          } catch (error) {}
        })
        .catch((err) => {
          console.log("Error ", err);
        });
  };

  return (
    <div>
      <div
        className="pink-header font-family-montserrat-bold"
        style={{ fontSize: "x-large" }}
      >
        Risk Profile
      </div>
      {isLoading ? (
        <>
          <div>Please Wait...</div>
          <SmallLoader />
        </>
      ) : (
        <>
          {!message ? (
            <div id="riskprofile-table" className="container-fluid">
              <div className="row no-gutters pb-5">
                <div className="d-flex dropdown__container ">
                  <div className="d-flex align-items-center">
                    <label className="font-weight-bold pl-3">
                      Select Program
                    </label>
                    <div className="pl-3">
                      <Select
                        placeholder={"Please Select"}
                        value={selectedCarrier}
                        className="carrier-dropdown dropdown"
                        options={carrierDropdown}
                        onChange={(selectOption) => {
                          setSelectedCarrier(selectOption);
                        }}
                      />
                    </div>
                  </div>

                  {/* <div className="d-flex align-items-center">
              <label className="font-weight-bold pl-5">Premium Type</label>
              <div className="pl-3">
                <Select
                  isDisabled={true}
                  placeholder="Manual Premium"
                  className="premium-dropdown dropdown"
                  value={selectedPremium}
                  options={premiumDropdown}
                  onChange={(selectOption) => setSelectedPremium(selectOption)}
                />
              </div>
            </div> */}
                </div>
                {selectedCarrier?.value &&
                  data?.[selectedCarrier?.value || "#1256793"] && (
                    <div
                      className={"ml-5 mt-2 download-link"}
                      onClick={downloadRiskProfile}
                    >
                      Download
                    </div>
                  )}
              </div>

              {createAllTables(selectedCarrier?.value)}
            </div>
          ) : (
            <div> {message}</div>
          )}
        </>
      )}
    </div>
  );
};

export default RiskProfile;
