import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../utils/common";
import { Auth } from "aws-amplify";
let isMobile = window.innerWidth < 900;

class HeaderSubPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userGroups: [],
      selectedDashboardOption: sessionStorage.getItem(
        "selectedDashboardOption"
      ),
      selectedUploadOption: sessionStorage.getItem("selectedUploadOption"),
    };
  }

  componentDidMount = async () => {
    let logoSize;
    let hrf = window.location.href;
    if (!hrf.includes("toolbar")) {
      if (hrf.includes("Quote")) {
        logoSize = "small";
      } else {
        logoSize = "large";
      }
    }
    if (logoSize) this.setState({ logoSize });

    let isLoggedIn,
      emailID,
      userGroups = [];
    try {
      isLoggedIn = await Auth.currentAuthenticatedUser();
      // console.log("isLoggedIn: ", isLoggedIn);
      if (isLoggedIn?.attributes?.name) {
        emailID = isLoggedIn.attributes.name;
      } else {
        emailID = isLoggedIn.attributes.email.split("@")[0];
      }
      userGroups =
        isLoggedIn.signInUserSession.accessToken.payload["cognito:groups"];
      this.setState({ userGroups });
      // console.log("emailID: ", emailID, userGroups);
    } catch (error) {
      console.log("comp did update err", error);
    }

    if (emailID) {
      this.setState({ emailID });
    }
  };

  handleDashboardSelect = (option) => {
    sessionStorage.setItem("selectedDashboardOption", option);
    sessionStorage.removeItem("selectedUploadOption");
    this.setState({ selectedDashboardOption: option }, () => {
      this.props.history.push(`/${option}`);
    });
  };

  handleUploadSelect = (option) => {
    sessionStorage.setItem("selectedUploadOption", option);
    sessionStorage.removeItem("selectedDashboardOption");
    this.setState({ selectedUploadOption: option }, () => {
      this.props.history.push(`/${option}`);
    });
  };

  render() {
    let {
      emailID,
      userGroups,
      selectedDashboardOption,
      selectedUploadOption,
    } = this.state;
    let quoteGroups = [process.env.REACT_APP_GET_QUOTE_GROUP];
    let raterGroups = [process.env.REACT_APP_RATER_GROUP];
    let dashboardGroups = [process.env.REACT_APP_DASHBOARD_GROUP];
    let uwDashboardGroup = [process.env.REACT_APP_UW_GROUPS];
    let uploadGroups = [
      process.env.REACT_APP_UPLOAD_COMPANY,
      process.env.REACT_APP_UPLOAD_RATER,
    ];
    let bookRollGroups = [process.env.REACT_APP_BOOK_ROLL];

    let displayQuote =
      !window.location.pathname.toLowerCase().includes("quote") &&
      userGroups.some((e) => quoteGroups.includes(e)) &&
      !userGroups.some((e) => uwDashboardGroup.includes(e));
    let displayRater =
      !window.location.pathname.toLowerCase().includes("rates") &&
      userGroups.some((e) => raterGroups.includes(e));
    let displayDashboard =
      !(window.location.pathname.toLowerCase() === "/dashboard") &&
      userGroups.some((e) => dashboardGroups.includes(e));
    let displayUwDashboard =
      !(window.location.pathname.toLowerCase() === "/uwportal") &&
      userGroups.some((e) => uwDashboardGroup.includes(e));
    let displayRaterDashboard =
      !(window.location.pathname.toLowerCase() === "/raterdashboard") &&
      userGroups.some((e) => dashboardGroups.includes(e));
    let displayUpload = userGroups.some((e) => uploadGroups.includes(e));
    let displayBookRoll = userGroups.some((e) => bookRollGroups.includes(e));

    let companyUploadGroups = userGroups.includes(
      process.env.REACT_APP_UPLOAD_COMPANY
    );
    let raterUploadGroups = userGroups.includes(
      process.env.REACT_APP_UPLOAD_RATER
    );

    return (
      <>
        {emailID && (
          <div className="d-flex mt-2 justify-content-between navcontent">
            {(displayRaterDashboard || displayDashboard) && (
              <div className="d-flex align-items-start dropdown">
                <span
                  className="dropdown-toggle text-dark"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Dashboard
                </span>
                <div className="dropdown-menu">
                  {selectedDashboardOption !== "dashboard" && (
                    <Link
                      className="dropdown-item nowrap"
                      to={"/dashboard"}
                      onClick={() => this.handleDashboardSelect("dashboard")}
                    >
                      Quote Tool
                    </Link>
                  )}
                  {selectedDashboardOption !== "raterdashboard" && (
                    <Link
                      className="dropdown-item"
                      to={"/raterdashboard"}
                      onClick={() =>
                        this.handleDashboardSelect("raterdashboard")
                      }
                    >
                      Rater
                    </Link>
                  )}
                  {displayBookRoll &&
                    selectedDashboardOption !== "bookrolldashboard" && (
                      <Link
                        className="dropdown-item"
                        to={"/BookDashboard"}
                        onClick={() =>
                          this.handleDashboardSelect("bookrolldashboard")
                        }
                      >
                        Book Roll
                      </Link>
                    )}
                </div>
              </div>
            )}

            {(displayQuote ||
              window.location.pathname.includes("UwPortal")) && (
              <Link
                to={"/quote"}
                className="dashboard-link text-dark"
                onClick={() => {
                  sessionStorage.clear();
                  window.location.href = "/quote";
                }}
              >
                Get Quote
              </Link>
            )}

            {displayUpload && (
              <div className="d-flex align-items-start dropdown">
                <span
                  className="dropdown-toggle text-dark"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Upload
                </span>
                <div className="dropdown-menu">
                  {companyUploadGroups &&
                    selectedUploadOption !== "DataUpload" && (
                      <Link
                        className="dropdown-item"
                        to={"/DataUpload"}
                        onClick={() => this.handleUploadSelect("DataUpload")}
                      >
                        Company Profile
                      </Link>
                    )}
                  {raterUploadGroups && selectedUploadOption !== "RaterUpload" && (
                    <Link
                      className="dropdown-item"
                      to={"/RaterUpload"}
                      onClick={() => this.handleUploadSelect("RaterUpload")}
                    >
                      Rater
                    </Link>
                  )}
                  {displayBookRoll &&
                    selectedUploadOption !== "bookrollupload" && (
                      <Link
                        className="dropdown-item"
                        to={"/BookUpload"}
                        onClick={() =>
                          this.handleUploadSelect("bookrollupload")
                        }
                      >
                        Book Roll
                      </Link>
                    )}
                </div>
              </div>
            )}

            {displayRater && (
              <Link to={"/rates"} className="dashboard-link text-dark">
                Rater
              </Link>
            )}

            {isMobile
              ? ""
              : displayUwDashboard && (
                  <div className="d-flex align-items-start dropdown navbar-font">
                    {displayUwDashboard && (
                      <a
                        className="dashboard-link text-dark"
                        href={"/UwPortal"}
                      >
                        UW Dashboard
                      </a>
                    )}
                  </div>
                )}

            {!isMobile && (
              <div className="d-flex align-items-start dropdown">
                <span
                  className="dropdown-toggle text-dark navbar-font"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {emailID}
                </span>
                <div className="dropdown-menu ">
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      logout();
                    }}
                  >
                    Logout
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default HeaderSubPart;
