import React from "react";
import DropZones from "./utils/DropZones";
import Header from "../../common/Header";
import SideTab from "../../../newcomponent/common/SideTab";
import styles from "./BookRollUpload.module.css";
import { carrierPeoProgramList } from "../../../utils/carrierList.json";
import { Form } from "react-bootstrap";
import moment from "moment";
import ReactMultiSelect from "../../common/ReactMultiSelect/ReactMultiSelect.jsx";
import { getUserType } from "../../../utils/getUserType";
let ismobile = window.innerWidth < 992;


class BookRollUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      peoDetails: { selectedPeo: "" },
      carrierList: { value: [] },
      userDetails: {},
    }
  }

  componentDidMount = async () => {
    const userDetails = await getUserType();
    if (userDetails?.userType === "peo" && userDetails?.peoList?.length > 0) {
      this.setState({
        peoDetails: { selectedPeo: userDetails?.peoList?.[0].value },
        userDetails,
      });
      sessionStorage.setItem("peoDetails", this.state.peoDetails);
    } else {
      this.setState({ userDetails });
    }
  }

  findCarriers = (peoValue, expectedStartDate) => {

    let carrierList = [];
    for (let data of carrierPeoProgramList) {
      if (data.devRemoved && process.env.REACT_APP_MODE === "dev") continue;
      if (data.prodRemoved && process.env.REACT_APP_MODE === "prod") continue;

      if (data.disableCarrier) continue;

      // if (
      //   data.displayPeoName === "LIB" &&
      //   data.actualCarrierName.indexOf("HEMIC") > -1
      // )
      //   continue;

      //includeCarrier check is only if the range is there, if no range then includeCarrier should be true.
      if (data.range) {
        expectedStartDate = moment(expectedStartDate, "YYYY-MM-DD");
        let includeCarrier = false;
        for (let range of data.range) {
          if (!range.to && range.from) {
            const fromDate = moment(range.from, "YYYY-MM-DD");

            if (fromDate.isSameOrBefore(expectedStartDate)) {
              includeCarrier = true;
              break;
            }
          }
          if (!range.from && range.to) {
            const toDate = moment(range.to, "YYYY-MM-DD");
            if (toDate.isAfter(expectedStartDate)) {
              includeCarrier = true;
              break;
            }
          }
          if (range.to && range.from) {
            const fromDate = moment(range.from, "YYYY-MM-DD");
            const toDate = moment(range.to, "YYYY-MM-DD");

            if (
              expectedStartDate.isSameOrAfter(fromDate) &&
              expectedStartDate.isBefore(toDate)
            ) {
              includeCarrier = true;
              break;
            }
          }
        }
        if (!includeCarrier) continue;
      }

      if (
        data.peo === peoValue ||
        (peoValue === "demo" && data.peo === "gms")
      ) {
        carrierList.push({
          label: data.actualCarrierName,
          value: data.carrier,
        });
      }
    }
    return carrierList;
  };

  handleSelectedList = (list) => {
    let { carrierList, peoDetails } = this.state;

    carrierList = {
      error: false,
      value: list,
    };

    this.setState({ carrierList });
  };

  render() {
    let { peoDetails,
      carrierList,
      userDetails,
    } = this.state;

    console.log(peoDetails.selectedPeo, carrierList.value);

    const expstartdate = moment()
    console.log(expstartdate);

    let _carrierList = [];
    for (let data of carrierPeoProgramList) {
      if (data.devRemoved && process.env.REACT_APP_MODE === "dev") continue;
      if (data.prodRemoved && process.env.REACT_APP_MODE === "prod") continue;
      if (data.disableCarrier) continue;
      if (data.range) {
        let expectedStartDate = moment(expstartdate, "YYYY-MM-DD");
        let includeCarrier = false;
        for (let range of data.range) {
          if (!range.to && range.from) {
            const fromDate = moment(range.from, "YYYY-MM-DD");

            if (fromDate.isSameOrBefore(expectedStartDate)) {
              includeCarrier = true;
              break;
            }
          }
          if (!range.from && range.to) {
            const toDate = moment(range.to, "YYYY-MM-DD");
            if (toDate.isAfter(expectedStartDate)) {
              includeCarrier = true;
              break;
            }
          }
          if (range.to && range.from) {
            const fromDate = moment(range.from, "YYYY-MM-DD");
            const toDate = moment(range.to, "YYYY-MM-DD");

            if (
              expectedStartDate.isSameOrAfter(fromDate) &&
              expectedStartDate.isBefore(toDate)
            ) {
              includeCarrier = true;
              break;
            }
          }
        }
        if (!includeCarrier) continue;
      }

      if (data.peo.toLowerCase() === peoDetails.selectedPeo) {
        // if (data.peo.toLowerCase().split(" ").join("") === peoDetails.selectedPeo) {
        _carrierList.push({
          label: data.actualCarrierName,
          value: data.carrier,
        });
      } else if (peoDetails.selectedPeo === "demo" && data.peo === "gms") {
        _carrierList.push({
          label: data.carrier?.toUpperCase(),
          value: data.carrier,
        });
      }
    }

    _carrierList.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });

    if (_carrierList.length > 0)
      _carrierList.unshift({
        label: "Select All",
        value: "select_all",
      });

    let peoOptions = [
      <option key={0} value="" disabled hidden>
        Please Select
      </option>,
    ];

    let peoUsed = new Set();

    for (let peoData of userDetails.peoList || []) {
      if (!peoUsed.has(peoData.value.toLowerCase())) {
        peoOptions.push(
          <option key={peoData.value} value={peoData.value}>
            {peoData.label}
          </option>
        );

        peoUsed.add(peoData.value.toLowerCase());
      }
    }

    return (
      <>
        <Header />

        <div className={styles.bookUploadContainer}>
          {ismobile ? <SideTab /> : " "}
          <div className={styles.header}>
            <h3>
              <b>Book Upload</b>
            </h3>
            <div style={{ backgroundColor: "black", marginTop: "-10px" }}>
              <hr />
            </div>
          </div>
          <div className={styles.bookUploadBody}>
            <div className="row">
              <div className="col-lg-6 col-md-12" id="peo-select-container">
                <Form.Group>
                  <b className="font-family-montserrat-semi-bold">
                    <label for="peo-select" className="mandatory">
                      Distributing partner
                    </label>
                  </b>
                  <select
                    required
                    id="peo-select"
                    name="peo-select"
                    type="text"
                    onChange={(event) => {
                      let _carrierList = this.findCarriers(
                        event.target.value,
                        expstartdate
                      );
                      this.setState({
                        peoDetails: {
                          selectedPeo: event.target.value,
                        },
                        carrierList: { value: _carrierList },
                      });
                    }}
                    onBlur={(e) => {
                      sessionStorage.setItem("peoDetails", JSON.stringify(peoDetails));
                      // sessionStorage.setItem("carrierList", JSON.stringify(carrierList));
                    }}
                    value={peoDetails.selectedPeo}
                    style={
                      peoDetails && peoDetails.error
                        ? { outline: "none", borderColor: "red" }
                        : {}
                    }
                    autoComplete="off"
                  >
                    {peoOptions}
                  </select>
                  <p className="errMsg" />
                </Form.Group>
              </div>
              <div
                className="col-lg-6 col-md-12"
                id="carrier-select-container"
              >
                <Form.Group>
                  <b className="font-family-montserrat-semi-bold">
                    <label for="peo-select" className="mandatory">
                      Carrier
                    </label>
                  </b>
                  <ReactMultiSelect
                    listToDisplay={_carrierList}
                    selectedList={carrierList.value || []}
                    handleSelectedListCallback={this.handleSelectedList}
                    style={
                      carrierList.error
                        ? { outline: "none", border: "1px solid red" }
                        : {}
                    }
                  />
                </Form.Group>
              </div>
            </div>
            <div className="company-profile-container"></div>
            <DropZones />
          </div>
        </div>
      </>
    );
  }
}
export default BookRollUpload;
